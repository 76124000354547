import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DragDropModule } from "@angular/cdk/drag-drop";

import {MatNativeDateModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';

import { NgxPaginationModule } from 'ngx-pagination';
import { NgxDropzoneModule } from 'ngx-dropzone';

import { FullCalendarModule } from '@fullcalendar/angular';

import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { GlobalErrorHandler } from './shared/global-error-handler';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { AppRoutes } from './app.routing';

import { ClientComponent } from './client/client.component';
import { ShowComponent as ClientShowComponent } from './client/show/show.component';
import { FollowUpComponent } from './followup/followup.component';
import { ShowComponent as FollowUpShowComponent } from './followup/show/show.component';
import { QuotationComponent } from './quotation/quotation.component';
import { ShowComponent as QuotationShowComponent } from './quotation/show/show.component';
import { SalesorderComponent } from './salesorder/salesorder.component';
import { ShowComponent as SalesorderShowComponent } from './salesorder/show/show.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ShowComponent as InvoiceShowComponent } from './invoice/show/show.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { AddComponent as ReceiptAddComponent } from './receipt/add/add.component';
import { ShowComponent as ReceiptShowComponent } from './receipt/show/show.component';
import { SalesCommissionComponent } from './sales-commission/sales-commission.component';
import { ShowComponent as SalesCommissionShowComponent } from './sales-commission/show/show.component';
import { RegionComponent } from './region/region.component';
import { ShowComponent as RegionShowComponent } from './region/show/show.component';
import { UserComponent } from './user/user.component';
import { ShowComponent as UserShowComponent } from './user/show/show.component';
import { StaffComponent } from './staff/staff.component';
import { ShowComponent as StaffShowComponent } from './staff/show/show.component';
import { GalleryComponent } from './gallery/gallery.component';
import { SettingComponent } from './setting/setting.component';

import { PartComponent } from './product/part/part.component';
import { ShowComponent as PartShowComponent } from './product/part/show/show.component';
import { ProductComponent } from './product/product.component';
import { ShowComponent as ProductShowComponent } from './product/show/show.component';
import { MeasurementComponent as ProductMeasurementComponent } from './product/measurement/measurement.component';
import { ShowComponent as ProductMeasurementShowComponent } from './product/measurement/show/show.component';
import { CategoryComponent as ProductCategoryComponent } from './product/category/category.component';
import { ShowComponent as ProductCategoryShowComponent } from './product/category/show/show.component';

import { StandardManagementComponent } from './shared/standard-management/standard-management.component';
import { StandardQuotationTableComponent } from './shared/standard-quotation-table/standard-quotation-table.component';
import { StandardShowComponent } from './shared/standard-show/standard-show.component';
import { LightboxComponent } from './shared/lightbox/lightbox.component';

import { DatePipe } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';





@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatCheckboxModule,
    MatStepperModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatButtonModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
  ],
  declarations: [
  ]
})
export class MaterialModule {}

@NgModule({
    imports:      [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        RouterModule.forRoot(AppRoutes,{
          useHash: true
        }),
        HttpClientModule,

        MaterialModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        NgxPaginationModule,
        NgxDropzoneModule,

        FullCalendarModule
    ],
    declarations: [
        LoginComponent,
        RegisterComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,

        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        DashboardComponent,

        ClientComponent, 
        ClientShowComponent,
        FollowUpComponent,
        FollowUpShowComponent,
        GalleryComponent,
        InvoiceComponent, 
        InvoiceShowComponent,
        PartComponent,
        PartShowComponent,
		    ProductComponent,
        ProductShowComponent,
        ProductCategoryComponent,
		    ProductCategoryShowComponent,
        ProductMeasurementComponent, 
		    ProductMeasurementShowComponent,
        QuotationComponent,
        QuotationShowComponent,
        ReceiptComponent,
        ReceiptAddComponent,
        ReceiptShowComponent,
        RegionComponent, 
		    RegionShowComponent,
        SalesorderComponent,
        SalesorderShowComponent,
        SalesCommissionComponent,
		    SalesCommissionShowComponent,
        SettingComponent,
        StaffComponent,
        StaffShowComponent,
        UserComponent, 
        UserShowComponent,

        StandardManagementComponent,
        StandardQuotationTableComponent,
        StandardShowComponent,

        PageNotFoundComponent,
        LightboxComponent
    ],
    providers : [
      MatNativeDateModule,
      { provide: ErrorHandler, useClass: GlobalErrorHandler },
	  DatePipe,
    ],
    bootstrap:    [ AppComponent ]
})
export class AppModule { }
