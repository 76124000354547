import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from './../shared/api.service';
import Swal from 'sweetalert2';
import { AppComponent } from './../app.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  prefix;
  
  folderList;
  filteredFolderList = [];
  fileList;
  filteredFileList = [];
  
  term;
  
  p = 0;
  
  subscriber;
  
  lightbox;
  lightboxIndex;
  
  imageDatas = {};
  loadingImg = 'assets/img/dummy-image.png';
  errorImg = 'assets/img/dummy-image.png';
  
  files = [];

  constructor(
	private router: Router,
	private aroute: ActivatedRoute,
	private apiService: APIService,
    private appComponent: AppComponent,
	private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
	  this.aroute.params.subscribe(params => {
		this.prefix = params['prefix'] || "";
		  
		this.stopObs();
		this.startObs();
	  });
  }
  
  ngOnDestroy() {
	this.stopObs();
  }
  
  startObs(): void {
	this.appComponent.getGalleriesObs({
		path: this.prefix
	}).subscribe((gallery : any) => {
		this.fileList = gallery.files;
		this.folderList = gallery.folders;
		this.filter();
	}, err => {	 
		// show error 
		this.apiService.handleStatusException(err);
	});
  }
  
  stopObs() {
	this.fileList = null;
	this.folderList = null;
	this.filteredFileList = [];
	this.filteredFolderList = [];
	if(this.subscriber) {
		this.subscriber.unsubscribe();
	}
  }
  
  haveFilter() {
	  return this.term ? true : false;
  }
  
  filter() {
	  if(!this.fileList && !this.folderList) {
		  return;
	  }
	  
	  if(!this.haveFilter()) {
		  this.filteredFileList = this.fileList;
		  this.filteredFolderList = this.folderList;
		  return;
	  }
	  
	  var term = this.term.toLowerCase();
	  
	  var newFiles = [];
	  
	  this.fileList.forEach(file => {
		  if(file && file.toLowerCase().indexOf(term) == -1) {
			return;
		  }
		  newFiles.push(file);
	  });
	  
	  this.filteredFileList = newFiles;
	  
	  var newFolders = [];
	  
	  this.folderList.forEach(path => {
		  if(path && path.toLowerCase().indexOf(term) == -1) {
			return;
		  }
		  newFiles.push(path);
	  });
	  
	  this.filteredFolderList = newFolders;
  }
  
  loadImage(item) {
	if(this.imageDatas[item]) {
		return;
	}
	  
	this.apiService.blob(
		this.apiService.getParameterizedURL(APIService.GALLERY_DOWNLOAD), {
			path: this.prefix + "/" + item
		}
	).then(blob => {
		this.imageDatas[item] = {
			src: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))
		};			
	}, err => {
		console.warn(err);
		this.imageDatas[item] = {
			src: this.errorImg,
			error: this.apiService.handleStatusException(err, true)
		};
	});
	
	return null;
  }
  
  viewFolder(path) {
	this.router.navigate(['gallery', { prefix: this.prefix + "/" + path }]);
  }
  
  viewImage(file, index) {
	this.lightboxIndex = index;
	this.lightbox = true;
  }
  
  getLightboxImages() {
	let images = [];
	
	this.filteredFileList.slice(this.p * 6, (this.p + 1) * 6)
	.forEach(file => {
		images.push({
			name: file.name,
			src: this.imageDatas[file]?.src || this.loadingImg
		});
	});
	
	return images;
  }
  
  download() {
	// let image = this.filteredFileList[this.lightboxIndex];
  }
  
  cancel(): void {
	this.lightbox = false;
  }

  trackByFn(index, item) {
	return item.id;
  }
  
  onSelect(evt) {
	evt.addedFiles.forEach((file, index) => {
		this.files.push(file);
		const formData : FormData = new FormData();
		formData.append('file', file, file.name);
		formData.append('path', this.prefix);
		
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.GALLERY_UPLOAD), formData, {
				"Content-Type":false
			}
		).then(path => {
			this.fileList.unshift(path);
			this.filter();
			
			this.files.splice(index, 1);
		}, err => {
			Swal.fire("Upload Gallery", this.apiService.handleStatusException(err, true), "error");
			this.files.splice(index, 1);
		});
	});
  }
}
