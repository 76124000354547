import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { APIService } from './../shared/api.service';
import { AppComponent } from './../app.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.css']
})
export class RegionComponent {
  
  title = "Sales Region";
  
  p;
  term;
  regionList;
  filteredList = [];
  
  subscriber;
  
  constructor(
    private router: Router,
	private apiService: APIService,
	private appComponent: AppComponent,
  ) {
		
  }
  
  ngOnInit(): void {	
	this.startObs();
  }
  
  ngOnDestroy() {
	this.stopObs();
  }
  
  startObs(): void {
	this.subscriber = this.appComponent.getSalesRegionsObs().subscribe(regionList => {
		this.regionList = regionList;
		
		this.filter();
	}, err => {
		// show error
		this.apiService.handleStatusException(err);
	});
  }
  
  stopObs() {
	this.subscriber.unsubscribe();
  }

  filter() {
	  if(!this.regionList) {
		  return;
	  }
	  
	  if(!this.term) {
		  this.filteredList = this.regionList;
		  return;
	  }
	  
	  var term = this.term.toLowerCase();
	  
	  var result = [];
	  
	  this.regionList.forEach(item => {
		  if(item.name && item.name.toLowerCase().indexOf(term) > -1) {
			result.push(item);
			return;
		  }
		  
		  if(item.users && item.users.find(x => x.name.toLowerCase().indexOf(term) > -1)) {
			result.push(item);
			return;
		  }		  
	  });
	  
	  this.filteredList = result;
  }
  
  edit(index, item) {
	this.router.navigate(['region/show', { uid: item.id }]);
  }
  
  remove(index, item) {
	Swal.fire({
		title: "Delete " + this.title,
		text: "Do you want to delete " + item.name + "?",
		icon: "warning",
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value) {
			item.deleting = true;
			
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.SALES_REGION_DELETE), {
					id: item.id
				}
			).then((res : any) => {
				this.regionList.splice(index, 1);
				
				this.filter();
			}, err => {
				item.deleting = false;
				Swal.fire("Error on " +  this.title + " Delete", this.apiService.handleStatusException(err, true), 'error');
			});
		}
	});
  }
  
  more(index, item) {
	this.router.navigate(['user/show', { sr_uid: item.id }]);
  }
	
  onPageChange(p) {
	this.p = p;
  }
  
  trackByFn(index, item) {
	return item.id;
  }

  getInstallerList(users: any[]) {
	return users.filter(x => x.role === "Installer");
  }

  getSalesmanList(users: any[]) {
	return users.filter(x => x.role === "Salesman");
  }
}