import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './../shared/auth.service';
import { AppComponent } from '../app.component';

declare const $: any;

@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
	
	user;
	version;
	selectedRegion;
	
	regionList = [];
	
	constructor(
		private router: Router,
		private authService: AuthService,
		private appComponent: AppComponent,
	) { }
	
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    ngOnInit() {
		this.user = this.authService.getUser();
		this.regionList = this.user.admin_regions.map(x => x.region);
		if (this.regionList.filter(x => x.id === parseInt(localStorage.getItem("region"))).length > 0) {
			this.selectedRegion = localStorage.getItem("region");
		}
		this.appComponent.getVersion(version => {
		  this.version = version;
		});
    }

    logout() {
		this.authService.setAuthHeader(null);
		this.router.navigate(['login']);
	}
	
	regionUpdate(evt) {
		localStorage.setItem("region", evt.target.value);
		window.location.reload();
	}
}
