import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from './../shared/api.service';
import { AppComponent } from './../app.component';
import Swal from 'sweetalert2';
import { moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, OnDestroy {

  filteredList = [];
  userList;
  term;
  p;
  
  translations = {};
  id;
  name;
  
  roleList = [];
  subscriber;
  role;
  
  constructor(
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
    private appComponent: AppComponent) { }

  ngOnInit(): void {
	this.aroute.params
	.subscribe(params => {
		this.role = params['role'];
		
		this.stopObs();
	
		this.startObs();
	});
  }

  ngOnDestroy() {
	this.stopObs();
  }
  
  startObs(): void {	  
    let data : any = { role: this.role, follow_up: 1 };
	
	if(this.role === "Salesman") {
		data.client = 1;
		data.sales_order = 1;
		data.quotation = 1;
		data.invoice = 1;
	}
	
	this.subscriber = this.appComponent.getUsersObs(data).subscribe(userList => {
		this.userList = userList;
		
		this.filter();
	}, err => {
		// show error
		this.apiService.handleStatusException(err);
	});
  }

  stopObs() {
	if(this.subscriber) {
		this.subscriber.unsubscribe();
	}
  }

  filter() {
	  if(!this.userList) {
		  return;
	  }
	  
	  if(!this.term) {
		  this.filteredList = this.userList;
		  return;
	  }
	  
	  var term = this.term.toLowerCase();
	  
	  var result = [];
	  
	  this.userList.forEach(item => {
		  if(item.name?.toLowerCase().indexOf(term) > -1) {
			result.push(item);
			return;
		  }
		  
		  if(item.email?.toLowerCase().indexOf(term) > -1) {
			result.push(item);
			return;
		  }
	  });
	  
	  this.filteredList = result;
  }

  add() {
    this.router.navigate(['user/show', { role: this.role }]);
  }

  edit(index, item) {
    this.router.navigate(['user/show', { role: this.role, uid: item.id }]);
  }

  remove(index, item) {
	var cThis = this;
	Swal.fire({
		title: "Delete User",
		text: "Do you want to delete " + item.name + "?",
		icon: "warning",
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value) {
			item.deleting = true;
			
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.USER_DELETE), {
					id: item.id
				}
			).then((res : any) => {
				this.userList.splice(index, 1);
				
				this.filter();
			}, err => {
				item.deleting = false;
				Swal.fire("Error on User Delete", this.apiService.handleStatusException(err, true), 'error');
			});
		}
	});
  }

  updateStatus(item, value) {
	if(!item.deleting) {
		item.deleting = true;
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.USER_ACTIVATION), {
				id: item.id,
				activation: value ? 1 : 0
			}
		).then((res : any) => {
			item.deleting = false;
		}, err => {
			item.deleting = false;
			Swal.fire("Error on User Activation", this.apiService.handleStatusException(err, true), 'error');
		});
	}
  }

  trackByFn(index, item) {
	return item.id;
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if(event.previousIndex != event.currentIndex) {
		this.stopObs();
		
		var target = this.filteredList[event.currentIndex];
		
		moveItemInArray(this.filteredList, event.previousIndex, event.currentIndex);
		
		// update server
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.USER_REORDER), {
				id: this.filteredList[event.currentIndex].id,
				target_id: target.id,
				is_add: event.currentIndex > event.previousIndex ? 1 : 0,
			}
		).then((res : any) => {
			this.startObs();
		}, err => {
			Swal.fire("Error on User Re-Ordering", this.apiService.handleStatusException(err, true), 'error');
		});
	}
  }
}
