import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from './../../shared/api.service';
import { LocalizeService } from './../../shared/localize.service';
import Swal from 'sweetalert2';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { merge } from 'rxjs';

@Component({
  selector: 'app-cw-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit {
  form = new FormGroup({
	name: new FormControl('', [Validators.required]),
	code: new FormControl('', []),
	description: new FormControl('', [Validators.required]),
	category_id: new FormControl('', []),
	have_discount: new FormControl(false, []),
	discounts: new FormArray([new FormGroup({
		id: new FormControl('', []),
		name: new FormControl('', []),
		discount: new FormControl(0, []),
		addon: new FormControl(0, []),
	})]),
	id: new FormControl('', []),
  });

  key;
  submitting;

  file;
  image;

  fileList = [];
  portfolioList = [];

  translations = {};
  
  haveDiscount = true;
  
  categoryList = [];
  
  constructor(  
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
	private localizeService: LocalizeService) {
  }

  ngOnInit(): void {
	this.translations = this.localizeService.getTranslation('product');
	
	this.key = this.aroute.snapshot.params['uid'];
	
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.PRODUCT_CATEGORY_LISTING)
	).then((categoryList : any) => {
		this.categoryList = categoryList;
	}, err => {
		//
	});
	
	this.initDependencyForm();
	this.initFormData();
  }
  
  async initDependencyForm() {
	this.form.controls.have_discount
		.valueChanges
		.subscribe(have_discount => {					
		var discounts = (this.form.controls.discounts as FormArray);
		
		if(have_discount) {		
			discounts.controls.forEach((discount : FormGroup) => {
			  discount.controls.name.setValidators([Validators.required]);
			  discount.controls.discount.setValidators([Validators.required]);
			  discount.controls.addon.setValidators([Validators.required]);
			  
			  discount.controls.name.updateValueAndValidity();
			  discount.controls.discount.updateValueAndValidity();
			  discount.controls.addon.updateValueAndValidity();
			});
		} else {
			discounts.controls.forEach((discount : FormGroup) => {
			  discount.controls.name.clearValidators();
			  discount.controls.discount.clearValidators();
			  discount.controls.addon.clearValidators();
			  
			  discount.controls.name.updateValueAndValidity();
			  discount.controls.discount.updateValueAndValidity();
			  discount.controls.addon.updateValueAndValidity();
			});
		}
	});
  }
  
  async initFormData() {
	if(this.key) {
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.PRODUCT_GET, [this.key])
		).then((data : any) => {
			this.form.patchValue({
				name: data.name,
				description: data.description,
				code: data.code,
				category_id: data.category_id || '',
				have_discount: data.discounts && data.discounts.length > 0,
			});
			
			if(data.discounts) {
				var discountArray = (this.form.controls.discounts as FormArray);
				
				data.discounts.forEach((discount, index) => {
					if(index >= discountArray.length) {
						this.addDiscount(discount.id, discount.name, discount.discount, discount.addon);
					} else {
						discountArray.controls[index].patchValue({
							id: discount.id,
							name: discount.name,
							discount: discount.discount,
							addon: discount.addon,
						});
					}
				});
				
				this.haveDiscount = data.discounts.length > 0;
			}
			
			this.image = data.image ? (APIService.STORAGE + data.image) : null;		
		}, err => {
			Swal.fire("Error on Product Show", this.apiService.handleStatusException(err), 'error');
		});
	} else {
	}
  }

  onSubmit() {
    if(this.submitting) {
		return;
	}

	if(this.form.valid) {
		this.submitting = true;
		var data = this.form.value;

		if(this.key) {
			data.id = this.key;
		}
		
		this.apiService.post(
			this.apiService.getParameterizedURL(this.key ? APIService.PRODUCT_UPDATE : APIService.PRODUCT_CREATE), data
		).then((res : any) => {
			var key = res.id;
			
			var fileProcessList = [];
			if(this.file) {
				const formData : FormData = new FormData();
				formData.append('image', this.file, this.file.name);
				formData.append('id', key);
				
				fileProcessList.push(
					this.apiService.post(
						this.apiService.getParameterizedURL(APIService.PRODUCT_UPLOAD), formData, {
							"Content-Type":false
						}
					).then((data : any) => {
						data.image = data;
					})
				);
			}			

			merge(fileProcessList).toPromise().then(() => {
				this.router.navigate(['product']).then(() => {
					this.submitting = false;
				});
			}, err => {
					this.submitting = false;
					Swal.fire("Error on Product Photo", this.apiService.handleStatusException(err), 'error');
				})
		} , err => {
			this.submitting = false;
			Swal.fire("Error on Product Submit", this.apiService.handleStatusException(err), 'error');
		});
	}
  }

  selectAll(type, value) {
	if(!this.form.get(type)) {
		return;
	}

	var result = [];
	
	for(var i = 0; i < this.form.get(type).value.length; i++) {
		result[i] = value;
	}

	this.form.get(type).patchValue(result);
  }

  // dropzone
  onRemove(i) {
	this.fileList.splice(this.fileList.findIndex(i), 1);
  }

  onSelect(evt) {
	this.fileList = evt.addedFiles;
  }

  // profile
  onFileChange(evt) {
	this.file = evt.target.files.item(0);
  }

  remove() {
	this.image = null;
  }
  
  addDiscount(id = "", name = "", discount = "", addon = "") {
	var discountArray = (this.form.controls.discounts as FormArray);
	
	var newDiscount = new FormGroup({
		id: new FormControl(id, []),
		name: new FormControl(name, [Validators.required]),
		discount: new FormControl(discount, [Validators.required]),
		addon: new FormControl(addon, [Validators.required]),
	});
	
	discountArray.push(newDiscount);
  }

  removeDiscount(index) {
	var discountArray = (this.form.controls.discounts as FormArray);
			
	discountArray.removeAt(index);
  }
}
