<app-standard-management [pageName]="title" [cardName]="title + ' Detail'" (pageChange)="onPageChange($event)" [addLink]="['/region/show']">
	<div toolbar class="form-group col-md-4 ml-auto">
		<input name="no" type="text" class="form-control" [(ngModel)]="term" placeholder="Search Keyword..." >
	</div>
	<ng-container table>		
		<thead>
			<tr>
			  <th>Name</th>
			  <th>Commission Percentage</th>
			  <th>Salesman(s) List</th>
			  <th>Installer(s) List</th>
			  <th>Action</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngIf="!regionList">
				<td colspan="7" class="text-center">Loading sales region...</td>
			</tr>
			<tr *ngIf="regionList && regionList.length == 0">
				<td colspan="7" class="text-center">No available sales region.</td>
			</tr>
			<tr *ngIf="term && filteredList.length == 0">
				<td colspan="7" class="text-center">No matched sales region.</td>
			</tr>
			<tr *ngFor="let item of filteredList | paginate: { itemsPerPage: 6, currentPage: p }; let i = index; trackBy: trackByFn">
				<td>{{ item.name }}</td>
				<td>{{ (item.commission_percentage || 0).toFixed(2) }}%</td>
				<td>
					<label *ngIf="getSalesmanList(item.users) == 0">No any salesman(s) under this sales region.</label>
					<div *ngFor="let user of getSalesmanList(item.users)" class="row">
						<div class="col-12">{{ user.name }}</div>
					</div>
				</td>
				<td>
					<label *ngIf="getInstallerList(item.users) == 0">No any installer(s) under this sales region.</label>
					<div *ngFor="let user of getInstallerList(item.users)" class="row">
						<div class="col-12">{{ user.name }}</div>
					</div>
				</td>
				<td>
				  <a class="btn btn-link btn-info btn-just-icon edit"><i class="material-icons" (click)="edit(i, item)" title="Edit">edit</i></a>
				  <a class="btn btn-link btn-danger btn-just-icon remove" [class.disabled]="item.deleting"><i class="material-icons" (click)="remove(i, item)" title="Remove">close</i></a>
				  <!-- <a class="btn btn-link btn-primary btn-just-icon more"><i class="material-icons" (click)="more(i, item)" title="Sales Order(s)">menu</i></a> -->
				</td>
			</tr>
		</tbody>
	</ng-container>
</app-standard-management>