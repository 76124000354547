import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from './../../shared/api.service';
import Swal from 'sweetalert2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-cw-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit {
  form = new FormGroup({
	quotation_id: new FormControl('', [Validators.required]),
	installer_id: new FormControl('', []),
	date: new FormControl('', [Validators.required]),
	completed_at: new FormControl('', [Validators.required]),
	client_remark: new FormControl('', []),
	address_remark: new FormControl('', []),
	user_remark: new FormControl('', []),
	id: new FormControl('', []),
  });

  key;
  qUID;
  submitting = false;
  
  title = "Follow Up";
  formControls : any[][] = [
	[
		{ class_name : "col-sm-6", type: 'label', text: "Loading Quotation..." },
		{ class_name : "col-sm-6", type: 'label', text: "Loading Installer..." },
	], [
		{ field: 'date', placeholder: "Installation Date", type: "date", class_name: "col-sm-6"},
		{ field: 'completed_at', placeholder: "Completed Date", type: "date", class_name: "col-sm-6"},
	], [
		{ field: 'client_remark', placeholder: "Remark under client field", type: "textarea" },
	], [
		{ field: 'address_remark', placeholder: "Remark under address field", type: "textarea" },
	], [
		{ field: 'user_remark', placeholder: "Remark under installer field", type: "textarea" },
	], [
		{ field: "uploaded image", type: "image_list", data: null, trackBy: this.imageTrackByFn, page: 0 }
	]
  ];
  
  imageDatas = {};
  errorImg = 'assets/img/dummy-image.png';
  
  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
	private sanitizer: DomSanitizer,) {
  }

  ngOnInit(): void {
	this.key = this.aroute.snapshot.params['uid'];
	this.qUID = this.aroute.snapshot.params['q_uid'];
	
	if(this.qUID) {
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.QUOTATION_GET, [this.qUID])
		).then((quotation : any) => {
			this.formControls.push([{
				field: 'quotation_id',
				type: 'hidden',
			}]);
			
			this.formControls[0][0] = {
				type: "label", 
				text: quotation?.name || "De-activated Quotation",
				class_name : "col-sm-6"
			};
			
			this.form.patchValue({
				quotation_id: quotation?.id,
			});
		}, err => {
			this.formControls[0][0] = {
				type: "label", 
				text: this.apiService.handleStatusException(err),
				class_name : "col-sm-6"
			};
		});
	} else {
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.QUOTATION_LISTING), {
				only_confirmed: true
			}
		).then((quotationList : any) => {
			this.formControls[0][0] = {
				field: 'quotation_id',
				type: "select", 
				placeholder: "Select Quotation",
				datas: quotationList,
				class_name : "col-sm-6"
			};
		}, err => {
			this.formControls[0][0] = {
				field: 'quotation_id',
				type: "select",
				placeholder: this.apiService.handleStatusException(err),
				datas: [],
				class_name : "col-sm-6"
			};
		});
	}
	
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.USER_LISTING), { role: "Installer" }
	).then((userList : any) => {
		this.formControls[0][1] = {
			field: 'installer_id',
			type: "select", 
			placeholder: "No Installer",
			datas: userList,
			class_name : "col-sm-6"
		};
	}, err => {
		this.formControls[0][1] = {
			field: 'installer_id',
			type: "select",
			placeholder: this.apiService.handleStatusException(err),
			datas: [],
			class_name : "col-sm-6"
		};
	});
	
	this.initFormData();
  }
  
  async initFormData() {
	if(this.key) {
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.FOLLOW_UP_GET, [this.key])
		).then((data : any) => {
			this.formControls[5][0].data = data.images;
			data.images.forEach(image => this.loadImage(image));
			this.form.patchValue({
				date: this.datePipe.transform(data.date, 'yyyy-MM-dd'),
				completed_at: data.completed_at ? this.datePipe.transform(data.completed_at, 'yyyy-MM-dd') : '',
				installer_id: data.installer_id,
				quotation_id: data.quotation_id,
				address_remark: data.address_remark,
				client_remark: data.client_remark,
				user_remark: data.user_remark
			});
		}, err => {
			Swal.fire(this.title + " Show", this.apiService.handleStatusException(err), 'error');
		});
	} else {
		this.formControls[5][0].data = [];
		this.loadImage({});
	}
  }

  onFormSubmit() {
	var data = this.form.value;

	if(this.key) {
		data.id = this.key;
	}

	this.apiService.post(
		this.apiService.getParameterizedURL(this.key ? APIService.FOLLOW_UP_UPDATE : APIService.FOLLOW_UP_CREATE), data
	).then((res : any) => {
		this.router.navigate(['follow_up']).then(() => {
			this.submitting = false;
		});
	} , err => {
		this.submitting = false;
		Swal.fire(this.title + (this.key ? " Submit" : " Update"), this.apiService.handleStatusException(err), 'error');
	});
  }

  
  loadImage(item: any) {
	const imageId = item.id;
	if(this.imageDatas[imageId]) {
		item.src = this.imageDatas[imageId].src;
		return;
	}
	  
	this.apiService.blob(
		this.apiService.getParameterizedURL(APIService.FOLLOW_UP_DOWNLOAD), {
			id: this.key, image_id: imageId
		}
	).then(blob => {
		this.imageDatas[imageId] = {
			src: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))
		};
		item.src = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
	}, err => {
		console.warn(err);
		this.imageDatas[imageId] = {
			src: this.errorImg,
			error: this.apiService.handleStatusException(err, true)
		};
	});
	
	return null;
  }

  imageTrackByFn(index, item) {
	return item.id;
  }
}
