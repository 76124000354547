<div class="main-content" style="margin-top: 0px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <h3 style="margin-bottom: 30px">Quotation</h3>
        </div>
            <div class="col-md-12">
                <div class="card">
                  <div class="card-header card-header-primary card-header-icon">
                    <div class="card-icon">
                      <i class="material-icons">assignment</i>
                    </div>
                      <h4 class="card-title">Quotation Detail</h4>
                  </div>
                    <div class="card-body">
                        <div class="toolbar">
							<div class="row form-group">
								<div class="col-sm-4">
									<select class="form-control" [(ngModel)]="only_confirmed" (ngModelChange)="filter()">
										<option value="">All Quotation</option>
										<option value="1">Confirmed</option>
										<option value="2">Pending Confirmation from salesman</option>
									</select>
								</div>
								<div class="col-sm-4">
									<select class="form-control" [(ngModel)]="user_id" (ngModelChange)="filter()">
										<option value="">All salesman</option>
										<option *ngFor="let item of userList" [value]="item.id">{{ item.name }}</option>
									</select>
								</div>
								<div class="col-md-4">
									<input name="no" type="text" class="form-control" [(ngModel)]="term" (ngModelChange)="filter()" placeholder="Search Keyword..." />
								</div>
							</div>
                        </div>
                        <div class="material-datatables">
                          <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                                <thead>
                                    <tr>
										<th></th>
										<th>Quotation No.</th>
										<th>Salesman</th>
										<th>
											<div class="row">
												<div class="col-6">Version Detail(s)</div>
												<div class="col-6 text-right">Total Amount</div>
											</div>
										</th>
										<th>Last Updated Date</th>
										<th>Status</th>
										<th>Action</th>
                                    </tr>
                                </thead>
                                <tbody cdkDropList (cdkDropListDropped)="onDrop($event)">
                                    <tr *ngIf="!quotationList">
										<td colspan="7" class="text-center">Loading quotation...</td>
									</tr>
									<tr *ngIf="quotationList && filteredList.length == 0 && !haveFilter()">
										<td colspan="7" class="text-center">No available quotation.</td>
									</tr>
									<tr *ngIf="quotationList && haveFilter() && filteredList.length == 0">
										<td colspan="7" class="text-center">No matched quotation.</td>
									</tr>
                                    <tr *ngFor="let item of filteredList | paginate: { itemsPerPage: 6, currentPage: p }; let i = index; trackBy: itemTrackBy" cdkDrag cdkDragLockAxis="y">
                                        <th class="col-xs">
											<div class="drag-handle" cdkDragHandle>
												<i class="material-icons">swap_vert</i>
											</div>
										</th>
										<td>{{ item.name }}</td>
										<td>{{ item.user?.name }}</td>
										<td>
											<div *ngFor="let v of item.versions" class="row">
												<div class="col-6">{{ item.name + "-" + v.version }}</div>
												<div class="col-6 text-right mt-auto">{{ "RM " + (v.total | number: '1.2-2') }}</div>
											</div>
										</td>
										<td class="text-center">{{ item.updated_at | date: 'short' }}</td>
										<td>
											<ng-container *ngIf="item.status == 'approved' else notApproved">
											  <ng-container *ngIf="getVersion(item); let cv">
												  <div class="form-group">											
													  {{ item.name + "-" + cv.version }}
												  </div>
												  <div class="form-group" style="margin-top: 10px;">
													<div class="row">
														<div class="col-6">
															Total Payment Amount
														</div>
														<div class="col-6 text-right">
															{{ cv.total | number: '1.2-2' }}
														</div>
													</div>
													<div class="row">
														<div class="col-6">
															Deposit Amount ({{ getDepositPercentage(cv) }}%)
														</div>
														<div class="col-6 text-right">{{ 
															"RM " + (getDepositPercentage(cv) ? 
															((cv.total * getDepositPercentage(cv) / 100) | number: '1.2-2') : "-")
														}}</div>
													</div>
													<div class="row">
														<div class="col-6">
															Received Amount ({{ item.paid_amount / cv.total }}%)
														</div>
														<div class="col-6 text-right" [class.text-red]="getDepositPercentage(cv) && 
															item.paid_amount < cv.total * getDepositPercentage(cv) / 100">{{
															"RM " + (item.paid_amount | number: '1.2-2')
														}}</div>
													</div>
												  </div>
												  
												  <div *ngIf="item.commission else generateCommission" class="form-group">
													<hr />
													<div class="row">
														<div class="col-6">
															Commission Amount
														</div>
														<div class="col-6 text-right">
															RM {{ item.commission.amount | number: '1.2-2' }}
														</div>
													</div>
													<div class="row">
														<div class="col-12">
															<button (click)="commission(item, true)" class="btn btn-warning">Re-calculate Commission</button>
														</div>
													</div>
												  </div>
												  <ng-template #generateCommission>
													<div class="row">
														<div class="col-12">
															<button (click)="commission(item, false)" class="btn btn-success">Generate Commission</button>
														</div>
													</div>
												  </ng-template>
											  </ng-container>
											</ng-container>
										  
											<ng-template #notApproved>
												<div *ngIf="item.confirmed_id else notConfirm" class="form-group">
												  <select [ngModel]="item.status ? item.status : ''" (ngModelChange)="updateStatus(item, $event)" class="form-control" [disabled]="item.updating">
													<option value="">Pending</option>
													<option value="rejected">Rejected</option>
													<option value="approved">Approved</option>
												  </select>
												</div>
												<ng-template #notConfirm>
													Pending Confirmation from salesman.
												</ng-template>
											</ng-template>
                                        </td>
                                        <td>
										  <a class="btn btn-link btn-success btn-just-icon view"><i class="material-icons" (click)="view(item)" title="View Detail(s)">launch</i></a>
                                          <!-- <a class="btn btn-link btn-info btn-just-icon edit"><i class="material-icons" (click)="edit(i, item)" title="Edit">edit</i></a> -->
                                		  <a class="btn btn-link btn-danger btn-just-icon remove" [class.disabled]="item.deleting"><i class="material-icons" (click)="remove(i, item)" title="Remove">close</i></a>
                                          <a class="btn btn-link btn-primary btn-just-icon more"><i class="material-icons" (click)="moreSalesOrder(item)" title="Sales Order(s)">menu</i></a>
                                          <a class="btn btn-link btn-warning btn-just-icon more"><i class="material-icons" (click)="moreInvoice(item)" title="Invoice(s)">menu</i></a>
                                          <a *ngIf="item.confirmed_id" class="btn btn-link btn-info btn-just-icon more"><i class="material-icons" (click)="moreFollowUp(item)" title="Follow Up(s)">menu</i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
						<div class="pull-right" style="margin-top: 15px">
							<pagination-controls (pageChange)="p = $event"></pagination-controls>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
