import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from './../shared/api.service';
import { LocalizeService } from './../shared/localize.service';
import { AppComponent } from './../app.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit, OnDestroy {

  filteredList = [];
  productList;
  productPartList;
  term;
  p;
  
  translations = {};
  id;
  name;

  subscriber;
  
  category_id = "";
  categoryList = [];
  loadedParts = {};

  constructor(
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
	private localizeService: LocalizeService,
    private appComponent: AppComponent,
    ) { }

  ngOnInit() {
	this.translations = this.localizeService.getTranslation('product');
	
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.PRODUCT_CATEGORY_LISTING)
	).then((categoryList : any) => {
		this.categoryList = categoryList;
	}, err => {
		this.apiService.handleStatusException(err);
	});
	
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.PRODUCT_PART_DISCOUNT_LISTING)
	).then((productPartList : any) => {
		this.productPartList = productPartList;
	}, err => {
		this.apiService.handleStatusException(err);
	});
	
	this.aroute.params
	.subscribe(params => {
		this.id = params['uid'];
		this.name = params['name'];
		
		this.term = "";
		this.p = 0;
		this.productList = null;
		this.filteredList = [];
		
		this.stopObs();
	
		this.startObs();
	});
  }
  
  ngOnDestroy() {
	this.stopObs();
  }
  
  startObs() {
	this.subscriber = this.appComponent.getProductsObs({
		id: this.id,
	}).subscribe((productList : any) => {
		this.productList = productList;
		
		this.filter();
	}, err => {
		this.apiService.handleStatusException(err);
	});
  }
  
  stopObs() {
	if(this.subscriber) {
		this.subscriber.unsubscribe();
	}
  }
  
  haveFilter() {
	  return this.term || this.category_id;
  }

  filter() {
	  if(!this.productList) {
		  return;
	  }
	  
	  if(!this.haveFilter()) {
		  this.filteredList = this.productList;
		  return;
	  }
	  
	  var term = this.term?.toLowerCase();
	  
	  var result = [];
	  
	  this.productList.forEach(item => {
		  if(this.category_id && item.category_id != this.category_id) {
			return;
		  }
		  
		  if(term) {
			  if(item.name && item.name.toLowerCase().indexOf(term) > -1) {
				result.push(item);
				return;
			  }
			  
			  if(item.description && item.description.toLowerCase().indexOf(term) > -1) {
				result.push(item);
				return;
			  }
			  
			  var part = this.loadedParts[item.id]?.parts.find(part => {
				  if(part.name && part.name.toLowerCase().indexOf(term) > -1) {
					return part;
				  }
				  
				  if(part.description && part.description.toLowerCase().indexOf(term) > -1) {
					return part;
				  }
			  });
			  
			  if(part) {
				result.push(item);
				return;
			  }
		  } else {			  
			result.push(item);
		  }
	  });
	  
	  this.filteredList = result;
  }

  add() {
    this.router.navigate(['product/show']);
  }

  edit(index, item) {
    this.router.navigate(['product/show', { uid: item.id }]);
  }
  
  sub(item) {
    this.router.navigate(['product/part', { p_uid: item.id, p_name: item.name }]);
  }

  remove(index, item) {
	Swal.fire({
		title: "Delete Product",
		text: "Do you want to delete " + item.name + "?",
		icon: "warning",
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value) {
			item.deleting = true;
			
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.PRODUCT_DELETE), {
					id: item.id
				}
			).then((res : any) => {
				item.deleting = false;
				this.productList.splice(index, 1);
				
				this.filter();
			}, err => {
				item.deleting = false;
				Swal.fire("Product Delete", this.apiService.handleStatusException(err, true), 'error');
			});
		}
	});
  }

  updateStatus(item, value) {
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.PRODUCT_ACTIVATION), {
			id: item.id,
			activation: value ? 1 : 0
		}
	).then((res : any) => {
		//
	}, err => {
		item.deleting = false;
		Swal.fire("Product Activation", this.apiService.handleStatusException(err, true), 'error');
	});	
  }

  trackByFn(index, item) {
	return item.id;
  }
  
  loopPart(array, part, level = 0) {
	part.level = level > 0 ? ("- ").padStart(level + 1, " ") : "";
	
	array.push(part);
	
	part.all_sub_parts.forEach(sub_part => {
		array = this.loopPart(array, sub_part, level + 1);
	});
	
	return array;
  }
  
  viewPart(item) {
	if(!item.viewing) {
		item.viewing = true;
		
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.PRODUCT_GET_PART, [item.id])
		).then((product : any) => {
			this.loadedParts[product.id] = {
				parts: product.parts,
				all_parts: product.all_parts,
			};
			item.viewing = false;
		}, err => {
			item.viewing = false;
			Swal.fire("Product Part Viewing", this.apiService.handleStatusException(err, true), 'error');
		});
	}
  }

  groupPart(key) {
	if(!this.loadedParts[key]) {
		return;
	}
	  
	var groupedParts = [];

	this.loadedParts[key].all_parts.forEach(part => {
		this.loopPart(groupedParts, part);
	});

	return groupedParts;
  }
  
  transfer(index, item) {
	if(item.transfering) {
		return;
	}
	
	if(!this.productPartList) {
		Swal.fire("Product", "Product Or Part is still loading, please try later again.", "warning");
		return;
	}
	
	var options = {
		0: "Not any",
		"root": "New Product",
	};
		
	this.productPartList.forEach(product => {
		options["m_" + product.id] = product.name;
		
		product.parts.forEach(part => {
			if(part.specifications.length == 0) {
				options["p_" + part.id] = "- " + part.name;
			}
		});
	});
	
	Swal.fire({
		title: "Product Transfer",		
		input: 'select',
		inputValue: item.parent_id || "",
		inputOptions: options,
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value && result.value != item.parent_id) {
			item.transfering = true;
			// this.stopObs();
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.PRODUCT_TRANSFER), {
					id: item.id,
					target_id: result.value,
				}
			).then((res : any) => {
				item.transfering = false;
				// this.startObs();
				Swal.fire("Success on Product Transfer", "Product Transferred.", 'success');
			}, err => {
				item.transfering = false;
				// this.startObs();
				Swal.fire("Product Transfer", this.apiService.handleStatusException(err, true), 'error');
			});
		}
	});
  }
  
  copy(index, item) {
	if(item.copying) {
		return;
	}
	
	if(!this.productPartList) {
		Swal.fire("Product", "Product Or Part is still loading, please try later again.", "warning");
		return;
	}
	
	var options = {
		"": "Select Product",
		"root": "New Product",
	};
		
	this.productPartList.forEach(product => {
		options["m_" + product.id] = product.name;
		
		product.parts.forEach(part => {
			if(part.specifications.length == 0) {
				options["p_" + part.id] = "- " + part.name;
			}
		});
	});
	
	Swal.fire({
		title: "Product Copy",
		input: 'select',
		inputOptions: options,
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value) {
			item.assigning = true;
			// this.stopObs();
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.PRODUCT_COPY), {
					id: item.id,
					target_id: result.value,
				}
			).then((res : any) => {
				item.copying = false;
				// this.startObs();				
				Swal.fire("Success on Product Copy", "Product Copied.", 'success');
			}, err => {
				item.copying = false;
				// this.startObs();
				Swal.fire("Product Copy", this.apiService.handleStatusException(err, true), 'error');
			});	
		}
	});
  }
}
