import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from './../../../shared/api.service';
import Swal from 'sweetalert2';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom, lastValueFrom, merge } from 'rxjs';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit {
  form = new FormGroup({
	name: new FormControl('', [Validators.required]),
	id: new FormControl('', []),
  });

  key;
  submitting;

  file;
  image;

  translations = {};
  
  constructor(  
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService) {
  }

  ngOnInit(): void {
	this.key = this.aroute.snapshot.params['uid'];
	
	this.initFormData();
  }
  
  async initFormData() {
	if(this.key) {
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.PRODUCT_CATEGORY_GET, [this.key])
		).then((data : any) => {
			this.form.patchValue({
				name: data.name,
			});	
			
			this.image = data.image;
		}, err => {
			Swal.fire("Error on Product Category Show", this.apiService.handleStatusException(err), 'error');
		});
	} else {
	}
  }

  onSubmit() {
    if(this.submitting) {
		return;
	}

	if(this.form.valid) {
		this.submitting = true;
		var data = this.form.value;
		
		if(this.key) {
			data.id = this.key;
		}
		this.apiService.post(
			this.apiService.getParameterizedURL(this.key ? APIService.PRODUCT_CATEGORY_UPDATE : APIService.PRODUCT_CATEGORY_CREATE), data
		).then((res : any) => {
			var key = res.id;
			var fileProcessList = [];
			if(this.file) {
				const formData = new FormData();
				formData.append('image', this.file, this.file.name);
				formData.append('id', key);
				console.log("FormData:", formData.has('image'));
				//for (let [key, value] of formData) {
					//console.log(`${key}: ${value}`)
				  //}
				console.log(key);
				fileProcessList.push(
					this.apiService.post(
						this.apiService.getParameterizedURL(APIService.PRODUCT_CATEGORY_UPLOAD), formData
					).then((data : any) => {
						data.image = data;
					})
				);
			}
			
			merge(fileProcessList).toPromise().then(() => {
				this.router.navigate(['product/category']).then(() => {
				this.submitting = false;
			});
			}, err => {
				this.submitting = false;
				Swal.fire("Error on Product Category Photo", this.apiService.handleStatusException(err), 'error');
			})
		} , err => {
			this.submitting = false;
			Swal.fire("Error on Product Category Submit", this.apiService.handleStatusException(err), 'error');
		});
		
	}
  }

  // profile
  onFileChange(evt) {
	this.file = evt.target.files.item(0);
  }

  remove() {
	this.image = null;
  }
}