import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from './../shared/api.service';
import { AuthService } from './../shared/auth.service';
import Swal from 'sweetalert2';
import { moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent implements OnInit, OnDestroy {

  filteredList = [];
  staffList;
  term;
  p;
  
  id;
  name;

  loop;
  user;

  constructor(
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
	private authService: AuthService,
    ) { }

  ngOnInit() {
	this.user = this.authService.getUser();

	this.aroute.params
	.subscribe(params => {
		this.id = params['uid'];
		this.name = params['name'];
		
		this.staffList = null;
		this.filteredList = [];
		
		this.initData();
	
		clearInterval(this.loop);	  
		this.startObs();
    });
  }
  
  ngOnDestroy() {
	this.stopObs();
  }
  
  startObs() {  
	this.loop = setInterval(() => this.initData(), 5000);
  }
  
  stopObs() {
	clearInterval(this.loop);	  
  }
  
  initData() {
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.STAFF_LISTING), {
			id: this.id
		}
	).then((res : any) => {
		this.staffList = res;
		
		this.filter();
	}, err => {
		console.log(this.apiService.handleStatusException(err));
		//Swal.fire("Error on Staff Listing", this.apiService.handleStatusException(err), 'error');
	});
  }

  filter() {
	  if(!this.staffList) {
		  return;
	  }
	  
	  if(!this.term) {
		  this.filteredList = this.staffList;
		  return;
	  }
	  
	  var term = this.term.toLowerCase();
	  
	  var result = [];
	  
	  this.staffList.forEach(item => {
		  if(item.name && item.name.toLowerCase().indexOf(term) > -1) {
			result.push(item);
			return;
		  }
		  
		  if(item.email && item.email.toLowerCase().indexOf(term) > -1) {
			result.push(item);
			return;
		  }
		  
		  if(item.description && item.description.indexOf(term) > -1) {
			result.push(item);
			return;
		  }
	  });
	  
	  this.filteredList = result;
  }

  add() {
    this.router.navigate(['staff/show']);
  }

  edit(index, item) {
    this.router.navigate(['staff/show', { uid: item.id }]);
  }
  
  sub(item) {
    this.router.navigate(['staff/part', { uid: item.id, name: item.name }]);
  }

  remove(index, item) {
	var cThis = this;
	Swal.fire({
		title: "Delete Staff",
		text: "Do you want to delete " + item.name + "?",
		icon: "warning",
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value) {
			item.deleting = true;
			
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.STAFF_DELETE), {
					id: item.id
				}
			).then((res : any) => {
				this.staffList.splice(index, 1);
				
				this.filter();
			}, err => {
				item.deleting = false;
				Swal.fire("Error on Staff Delete", this.apiService.handleStatusException(err), 'error');
			});
		}
	});
  }

  updateStatus(item, value) {
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.STAFF_ACTIVATION), {
			id: item.id,
			activation: value ? 1 : 0
		}
	).then((res : any) => {
		//
	}, err => {
		item.deleting = false;
		Swal.fire("Error on Staff Activation", this.apiService.handleStatusException(err), 'error');
	});	
  }

  trackByFn(index, item) {
	return item.id;
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if(event.previousIndex != event.currentIndex) {
		this.stopObs();
		
		var target = this.filteredList[event.currentIndex];
		
		moveItemInArray(this.filteredList, event.previousIndex, event.currentIndex);
		
		// update server
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.CLIENT_REORDER), {
				id: this.filteredList[event.currentIndex].id,
				target_id: target.id,
				is_add: event.currentIndex > event.previousIndex ? 1 : 0,
			}
		).then((res : any) => {
			this.startObs();
		}, err => {
			Swal.fire("Error on Staff Re-Ordering", this.apiService.handleStatusException(err, true), 'error');
		});
	}
  }
}
