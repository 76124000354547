<div class="main-content" style="margin-top: 0px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <h3 style="margin-bottom: 30px">Receipt</h3>
        </div>
        <div class="col-lg-4 ml-auto" >
          <a class="btn btn-fill btn-rose " style="float: right;" (click)="add()">Add New</a>
        </div>
            <div class="col-md-12">
                <div class="card">
                  <div class="card-header card-header-primary card-header-icon">
                    <div class="card-icon">
                      <i class="material-icons">assignment</i>
                    </div>
                      <h4 class="card-title">Receipt Detail</h4>
                  </div>
                    <div class="card-body">
                        <div class="toolbar">
							<div class="row form-group">
								<div class="col-sm-4">
									<select class="form-control" [(ngModel)]="client_id" (ngModelChange)="filter()">
										<option value="">All client</option>
										<option *ngFor="let client of clientList" [value]="client.id.toString()">{{ client.company_name || client.person }}</option>
									</select>
								</div>
								<div class="offset-sm-4 col-md-4">
									<input name="no" type="text" class="form-control" [(ngModel)]="term" (ngModelChange)="filter()" placeholder="Search Keyword..." />
								</div>
							</div>
                        </div>
                        <div class="material-datatables">
                        <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                          <thead>
                              <tr>
						                     <th></th>
                                <th>Receipt No.</th>
                                <th>Client</th>
                                <th>Cheque No.</th>
                                <th>Total Amount</th>
                                <th>Last Updated Date</th>
                                <th>Action</th>
                              </tr>
                          </thead>
                          <tbody cdkDropList (cdkDropListDropped)="onDrop($event)">
                            <tr *ngIf="!receiptList">
          										<td colspan="7" class="text-center">Loading receipt...</td>
          									</tr>
          									<tr *ngIf="receiptList && receiptList.length == 0">
          										<td colspan="7" class="text-center">No available receipt.</td>
          									</tr>
          									<tr *ngIf="term && filteredList.length == 0">
          										<td colspan="7" class="text-center">No matched receipt.</td>
          									</tr>
                            <tr *ngFor="let item of filteredList | paginate: { itemsPerPage: 6, currentPage: p }; let i = index; trackBy: trackByFn" cdkDrag cdkDragLockAxis="y">
                              <th class="col-xs">
        											<div class="drag-handle" cdkDragHandle>
        												<i class="material-icons">swap_vert</i>
        											</div>
        										</th>
        										<td>{{ item.name }}</td>
        										<td>{{ item.client?.company_name || item.client?.person }}</td>
                                        <td>{{ item.cheque_no }}</td>
                                        <td>
      											<label *ngIf="item.receipt_on_invoices == 0">No any invoice.</label>
      											<div *ngFor="let roi of item.receipt_on_invoices" class="row" style="white-space: pre-wrap;">
      												<div class="col-6">{{ roi.invoice?.name }}</div>
      												<div class="col-6 text-right">{{ "RM " + (roi.paid_amount | number: '1.2-2') }}</div>
      											</div>
      										</td>
                                        <td class="text-center">{{ item.updated_at | date: 'short' }}</td>
                                        <td>
										  <a class="btn btn-link btn-success btn-just-icon like"><i class="material-icons" (click)="show(i, item)" title="View Details">launch</i></a>
										  <a class="btn btn-link btn-info btn-just-icon like"><i class="material-icons" (click)="edit(i, item)" title="Edit">edit</i></a>
                                		  <a class="btn btn-link btn-danger btn-just-icon remove"><i class="material-icons" (click)="remove(i, item)" title="Remove">close</i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
						<div class="pull-right" style="margin-top: 15px">
							<pagination-controls (pageChange)="p = $event"></pagination-controls>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
