import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './../shared/auth.service';
import { LocalizeService } from './../shared/localize.service';
import { APIService } from './../shared/api.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  translations = {};
  errors = {};
  submitting;

  version;
  hide = true;
  
  constructor(
    private router: Router,
	private authService: AuthService,
	private apiService: APIService,
	private localizeService: LocalizeService,	
	private appComponent: AppComponent,
  ) { }

  ngOnInit(): void {
	this.translations = this.localizeService.getTranslation('login');
	
	this.appComponent.getVersion(version => {
	  this.version = version;
    });
  }
  
  onSubmit(f) {
	var data = f.value;
	
	var hasError = false;
	this.errors = [];
	
	if(!data.email) {
		this.errors['email'] = "Email Address is required.";
		hasError = true;
	}
	
	if(!data.password) {
		this.errors['password'] = "Password is required.";
		hasError = true;
	}
	
	if(hasError || this.submitting) {
		return;
	}
	
	this.submitting = true;
	
	this.apiService.post(APIService.LOGIN, { email: data.email, password: data.password })
	.then((res : any) => {
		this.authService.setAuthHeader(res);
		this.authService.setUser(res.user);
			
		this.router.navigate([sessionStorage.getItem('route') || 'dashboard']).then(() => {
			sessionStorage.removeItem('route');
			f.resetForm();
			this.submitting = false;			
		});
	}, (err) => {
		this.submitting = false;
		
		if((err.status == 422 || err.status == 429) && err.error) {
			Object.keys(err.error.errors).forEach(errField => {
				this.errors[errField] = err.error.errors[errField][0];
			});
		} else {
			this.errors['password'] = this.apiService.handleStatusException(err, true);
		}
	});
  }
  
  forgot(){	
	this.router.navigate(['forgot']);
  }
}
