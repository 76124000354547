import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from './../../shared/api.service';
import { LocalizeService } from './../../shared/localize.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit {
  
  title = "Receipt";
  receipt;
  settingList = {};
  deleting;
  defaultCompanyName = "Cash Sales";

  constructor(
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
	private localizeService: LocalizeService,
	private sanitizer: DomSanitizer
	) { }

  ngOnInit(): void {		
	let uid = this.aroute.snapshot.params['uid'];
	
	if(uid) {
		this.initFormData(uid);
	}
  }
  
  initSetting(regionId) {
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.SETTING_GET_MULTI), {
			keys: [
				'company_name',
				'company_email',
				'company_website',
				'company_address',
				'company_fax',
				'company_sst',
				'company_logo',
				'company_reg_no',
				'company_phone',
				'company_chop',
				'company_chop_text',
				'note',
				'deposit_percentage',
			],
			region_id: regionId
		}
	).then((settingList : any) => {
		this.settingList = settingList;
	}, err => {
		this.apiService.handleStatusException(err);
	});
  }
  
  initFormData(uid) {
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.RECEIPT_GET, [uid])
	).then((receipt : any) => {
		this.receipt = receipt;
		
		this.initSetting(this.receipt?.region_id);
	}, err => {
		Swal.fire(this.title + " Show", this.apiService.handleStatusException(err), 'error');
	});
  }
  
  getSanitizerURL(path) {
	  return this.sanitizer.bypassSecurityTrustResourceUrl(APIService.STORAGE + path);
  }

  groupedInvoices() {
	  let grouped = [];
	  let rowIndex = 0;
	  
	  for(let i = 0; i < 15; i++) {
		if(!grouped[rowIndex]) {
		  grouped[rowIndex] = [];
		}
		  
		grouped[rowIndex].push(this.receipt?.receipt_on_invoices[i]);
		  
		if(i % 5 == 4) {
		  rowIndex++;
		}
	  }
	  
	  return grouped;
  }
  
  share() {
	window.open(APIService.RECEIPT_SHARE + "?id=" + this.receipt.id, "_blank");
  }
  
  pdf() {
	window.open(APIService.RECEIPT_PDF + "?id=" + this.receipt.id, "_blank");
  }
  
  edit() {
	this.router.navigate(['receipt/add', { uid: this.receipt.id }]);
  }
  
  remove() {
	if(this.deleting) {
		return;
	}
	
	Swal.fire({
		title: "Delete Receipt",
		text: "Do you want to delete " + this.receipt.name + "?",
		icon: "warning",
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value) {
			this.deleting = true;
			
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.RECEIPT_DELETE), {
					id: this.receipt.id
				}
			).then((res : any) => {
				this.router.navigate(['receipt']);
				this.deleting = false;
			}, err => {
				this.deleting = false;
				Swal.fire("Receipt Delete", this.apiService.handleStatusException(err, true), 'error');
			});
		}
	});
  }
  
  getCompanyName() {
	const companyName = this.receipt?.client?.company_name;
	
	if (!companyName || companyName.toLowerCase() === this.defaultCompanyName.toLowerCase()) {
		return this.receipt?.client?.person;
	} else {
		return companyName;
	}
  }
}
