import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { APIService } from './../shared/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  templateList = [];
  currentList = {};
  
  regionList = [];
  regionId;

  constructor(
	private apiService: APIService,
	private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.SETTING_LISTING)
	).then((setting: any) => {
		this.templateList = setting.template;
		this.currentList = setting.current;
	}, err => {
		Swal.fire("Setting Details", this.apiService.handleStatusException(err, true), 'error');
	});
	
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.SALES_REGION_LISTING)
	).then((regionList : any) => {
		this.regionList = regionList;
	}, err => {
		Swal.fire("Setting Sales Region Listing", this.apiService.handleStatusException(err, true), 'error');
	});
  }
  
  getSetting(key: string) {
	  return this.templateList.find(x => x.key == key);
  }
  
  getRegionSetting(key: string) {
	  return this.currentList[key]? this.currentList[key][this.regionId] : "";
  }
  
  setRegionSetting(key, regionId, value) {
	  if(!this.currentList[key]) {
		  this.currentList[key] = {};
	  }
	  
	  this.currentList[key][regionId] = value;
  }
  
  getSanitizerURL(path) {
	  return this.sanitizer.bypassSecurityTrustResourceUrl(APIService.STORAGE + path);
  }
  
  updateSetting(evt, setting) {
	if(!setting.updating) {
		setting.updating = true;
		
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.SETTING_UPDATE), {
				region_id: this.regionId,
				key: setting.key,
				value: evt.target.value
			}
		).then((res : any) => {
			this.setRegionSetting(res.key, res.region_id, res.value);
			setting.updating = false;
		}, err => {
			setting.updating = false;

			Swal.fire("Error on Update " + setting.name, this.apiService.handleStatusException(err), "error");
		});
	}
  }
  
  onFileChange(evt, setting) {
	if(!setting.updating) {
		setting.updating = true;
		var file = evt.target.files.item(0);
		  
		var formData : FormData = new FormData();
		formData.append('image', file, file.name);
		formData.append('key', setting.key);
		formData.append('region_id', this.regionId);
		
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.SETTING_UPDATE), formData, {
				"Content-Type":false
			}
		).then((res : any) => {
			this.setRegionSetting(res.key, res.region_id, res.value);
			setting.updating = false;
		}, err => {
			setting.updating = false;

			Swal.fire("Error on Update " + setting.name, this.apiService.handleStatusException(err), "error");
		});
	}
  }
  
  remove(setting) {
	if(!setting.updating) {
		setting.updating = true;
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.SETTING_UPDATE), {
				region_id: this.regionId,
				key: setting.key,
				image: ""
			}
		).then((res : any) => {
			this.setRegionSetting(res.key, res.region_id, res.value);
			setting.updating = false;
		}, err => {
			setting.updating = false;

			Swal.fire("Error on Update " + setting.name, this.apiService.handleStatusException(err), "error");
		});
	}
  }
  
  onTabChanged(evt) {
	  this.regionId = this.regionList[evt.index].id;
  }
}
