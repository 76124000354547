<div class="logo">
  <a href="#" class="simple-text logo-normal">
    <img src="/assets/img/ergt_demo.png" width="60%" style="margin: 0 auto; display: block;">
  </a>
</div>

<div class="sidebar-wrapper" style="padding-bottom: 120px">
  <div class="user">
      <div class="photo">
          <img src="./assets/img/placeholder.jpg" />
      </div>
      <div class="user-info">
          <a data-toggle="collapse" href="#collapseExample" class="collapsed">
              <span>
                  {{ user.name }}
                  <b class="caret"></b>
              </span>
          </a>
          <div class="collapse" id="collapseExample">
              <ul class="nav">
                  <li class="nav-item"routerLinkActive="active" >
                      <a [routerLink]="['/setting']" class="nav-link">
            <i class="material-icons">settings</i>
            <span class="sidebar-normal">Settings 设置</span>
                      </a>
                  </li>
                  <li class="nav-item">
                      <a  class="nav-link">
                          <span class="sidebar-mini">L</span>
                          <span class="sidebar-normal" (click)="logout()">Logout 登出</span>
                      </a>
                  </li>
              </ul>
          </div>
      </div>
  </div>
<div *ngIf="regionList?.length > 1" class="col-12">
  <select class="form-control" (change)="regionUpdate($event)">
  <option value="" [selected]="!selectedRegion">All Region</option>
  <option *ngFor="let region of regionList" [value]="region.id" [selected]="selectedRegion == region.id">{{ region.name }}</option>
  </select>
</div>
  <ul class="nav">
      <li routerLinkActive="active" class="nav-item">
          <a [routerLink]="['/dashboard']" class="nav-link">
              <i class="material-icons">dashboard</i>
              <p>Dashboard 报表看板</p>
          </a>
      </li>
      <li routerLinkActive="active" class="nav-item">
          <a [routerLink]="['/quotation']" class="nav-link">
              <i class="material-icons">article</i>
              <p>Quotation 报价单</p>
          </a>
      </li>
      <li routerLinkActive="active" class="nav-item">
          <a [routerLink]="['/sales_order']" class="nav-link">
              <i class="material-icons">article</i>
              <p>Sales Order 订单</p>
          </a>
      </li>
      <li routerLinkActive="active" class="nav-item">
          <a [routerLink]="['/invoice']" class="nav-link">
              <i class="material-icons">article</i>
              <p>Invoice 发票</p>
          </a>
      </li>
      <li routerLinkActive="active" class="nav-item">
          <a [routerLink]="['/receipt']" class="nav-link">
              <i class="material-icons">article</i>
              <p>Receipt 收据</p>
          </a>
      </li>
      <li routerLinkActive="active" class="nav-item">
          <a [routerLink]="['/sales_commission']" class="nav-link">
              <i class="material-icons">paid</i>
              <p>Sales Commission 佣金</p>
          </a>
      </li>
      <li routerLinkActive="active" class="nav-item">
          <a [routerLink]="['/follow_up']" class="nav-link">
              <i class="material-icons">done</i>
              <p>Follow Up 跟进</p>
          </a>
      </li>
  <li routerLinkActive="active" class="nav-item">
          <a data-toggle="collapse" href="#product" class="nav-link">
              <i class="material-icons">collections</i>
              <p>Product 产品<b class="caret"></b></p>
          </a>

          <!--Display the submenu items-->
          <div id="product" class="collapse">
              <ul class="nav">
                  <li routerLinkActive="active" class="nav-item">
                      <a [routerLink]="['/product']" class="nav-link">
            <i class="material-icons">attach_money</i>
                          <span class="sidebar-normal">Product Listing 产品列表</span>
                      </a>
                  </li>
                  <li routerLinkActive="active" class="nav-item">
                      <a [routerLink]="['/product/measurement']" class="nav-link">
            <i class="material-icons">straighten</i>
                          <span class="sidebar-normal">Product Measurement 产品丈量单位</span>
                      </a>
                  </li>
                  <li routerLinkActive="active" class="nav-item">
                      <a [routerLink]="['/product/category']" class="nav-link">
            <i class="material-icons">category</i>
                          <span class="sidebar-normal">Product Category 产品分类</span>
                      </a>
                  </li>
              </ul>
          </div>
      </li>
      <li routerLinkActive="active" class="nav-item">
          <a [routerLink]="['/region']" class="nav-link">
              <i class="material-icons">map</i>
              <p>Sales Region 销售区域</p>
          </a>
      </li>
      <li routerLinkActive="active" class="nav-item">
          <a [routerLink]="['/client']" class="nav-link">
              <i class="material-icons">face</i>
              <p>Client Management 客户</p>
          </a>
      </li>
      <li routerLinkActive="active" class="nav-item">
          <a [routerLink]="['/user', { role: 'Salesman' }]" class="nav-link">
              <i class="material-icons">face</i>
              <p>Salesman Management <br />销售员</p>
          </a>
      </li>
      <li routerLinkActive="active" class="nav-item">
          <a [routerLink]="['/user', { role: 'Installer' }]" class="nav-link">
              <i class="material-icons">face</i>
              <p>Installer Management <br />安装员</p>
          </a>
      </li>
      <li routerLinkActive="active" class="nav-item">
          <a [routerLink]="['/staff']" class="nav-link">
              <i class="material-icons">face</i>
              <p>Admin Management 管理员</p>
          </a>
      </li>
      <!--<li routerLinkActive="active" class="nav-item">
          <a data-toggle="collapse" href="#user" class="nav-link">
              <i class="material-icons">dashboard</i>
              <p>User Management<b class="caret"></b></p>
          </a>

          -Display the submenu items
          <div id="user" class="collapse" >
              <ul class="nav">
                  <li routerLinkActive="active" class="nav-item">
                      <a [routerLink]="['/page']" class="nav-link">
                          <span class="sidebar-mini">S</span>
                          <span class="sidebar-normal">Salesman 1</span>
                      </a>
                  </li>
                  <li routerLinkActive="active" class="nav-item">
                      <a [routerLink]="['/category']" class="nav-link">
                          <span class="sidebar-mini">S</span>
                          <span class="sidebar-normal">Salesman 2</span>
                      </a>
                  </li>

                  <li routerLinkActive="active" class="nav-item">
                      <a [routerLink]="['/category']" class="nav-link">
                          <span class="sidebar-mini">S</span>
                          <span class="sidebar-normal">Salesman 3</span>
                      </a>
                  </li>

                  <li routerLinkActive="active" class="nav-item">
                      <a [routerLink]="['/category']" class="nav-link">
                          <span class="sidebar-mini">S</span>
                          <span class="sidebar-normal">Salesman 4</span>
                      </a>
                  </li>

                  <li routerLinkActive="active" class="nav-item">
                      <a [routerLink]="['/category']" class="nav-link">
                          <span class="sidebar-mini">S</span>
                          <span class="sidebar-normal">Salesman 5</span>
                      </a>
                  </li>
              </ul>
          </div>
      </li>-->
      <li routerLinkActive="active" class="nav-item">
          <a [routerLink]="['/gallery']" class="nav-link">
              <i class="material-icons">collections</i>
              <p>Gallery 相册</p>
          </a>
      </li>
  </ul>
</div>

<div style="position: absolute; bottom: 10px; right: 50px;">Version: {{ version }}</div>