<div class="main-content" style="margin-top: 0px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <h3 style="margin-bottom: 30px">{{ role == 'Salesman' ? "Salesman 销售员" : "Installer 安装员" }}</h3>
        </div>
        <div class="col-lg-4 ml-auto" >
          <a class="btn btn-fill btn-rose" style="float: right;" (click)="add()">Add New 添加</a>
        </div>
            <div class="col-md-12">
                <div class="card">
                  <div class="card-header card-header-primary card-header-icon">
                    <div class="card-icon">
                      <i class="material-icons">assignment</i>
                    </div>
                      <h4 class="card-title">{{ role == 'Salesman' ? "Salesman Detail" : "Installer Detail" }}</h4>
                  </div>
                    <div class="card-body">
                        <div class="toolbar">
                           <div class="form-group col-md-4 ml-auto">
                            <input name="no" type="text" class="form-control" [(ngModel)]="term" (ngModelChange)="filter()" placeholder="Search Keyword..." >
                          </div>
                        </div>
                        <div class="material-datatables">
                          <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                                <thead>
                                    <tr>
                    <th></th>
                                      <th>Name</th>
                                      <th>Email</th>
                                      <th>Statistic</th>
                                      <!-- <th>Role</th> -->
                                      <th>Activation</th>
                                      <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody cdkDropList (cdkDropListDropped)="onDrop($event)">
                                    <tr *ngIf="!userList">
                    <td colspan="7" class="text-center">Loading {{ (role || "Salesman").toLowerCase() }}...</td>
                  </tr>
                  <tr *ngIf="userList && userList.length == 0">
                    <td colspan="7" class="text-center">No available {{ (role || "Salesman").toLowerCase() }}.</td>
                  </tr>
                  <tr *ngIf="term && filteredList.length == 0">
                    <td colspan="7" class="text-center">No matched {{ (role || "Salesman").toLowerCase() }}.</td>
                  </tr>
                                    <tr *ngFor="let item of filteredList | paginate: { itemsPerPage: 6, currentPage: p }; let i = index; trackBy: trackByFn" cdkDrag cdkDragLockAxis="y">
                                        <th class="col-xs">
                      <div class="drag-handle" cdkDragHandle>
                        <i class="material-icons">swap_vert</i>
                      </div>
                    </th>
                    <td>{{ item.name }}</td>
                                        <td>{{ item.email }}</td>
                                        <td>
                      <div *ngIf="role == 'Salesman' else installer" class="row">				
                        <div class="col-12">Client: {{ item.clients ? item.clients.length : 0 }}</div>
                        <div class="col-12">Follow Up: {{ item.role === "Installer" ? (item.follow_ups ? item.follow_ups.length : 0) : (item.sales_follow_ups ? item.sales_follow_ups.length : 0) }}</div>							
                        <div class="col-12">Quotation: {{ item.quotations ? item.quotations.length : 0 }}</div>
                        <div class="col-12">Sales Order: {{ item.sales_orders ? item.sales_orders.length : 0 }}</div>
                        <div class="col-12">Invoice: {{ item.invoices ? item.invoices.length : 0 }}</div>
                      </div>
                      <ng-template #installer>
                        <div class="row">
                          <div class="col-12">Follow Up: {{ item.follow_ups ? item.follow_ups.length : 0 }}</div>
                        </div>
                      </ng-template>
                    </td>
                                        <!-- <td> -->
                                          <!-- <select [ngModel]="item.role_id ? item.role_id: ''" (ngModelChange)="updateRole(item, $event)" class="form-control"> -->
                                            <!-- <option value="">No role selected</option> -->
                                            <!-- <option *ngFor="let role of roleList" value="{{ role.id }}">{{ role.name }}</option> -->
                                          <!-- </select> -->
                                        <!-- </td> -->
                    <td>
                                          <div class="togglebutton">
                                            <label>
                                              <input [disabled]="item.deleting" type="checkbox" [ngModel]="!item.deleted_at" (ngModelChange)="updateStatus(item, $event)" />
                                              <span class="toggle"></span>
                                            </label>
                                          </div>
                                        </td>
                    <td>
                      <a class="btn btn-link btn-info btn-just-icon like"><i class="material-icons" (click)="edit(i, item)" title="Edit">edit</i></a>
                      <a class="btn btn-link btn-danger btn-just-icon remove"><i class="material-icons" (click)="remove(i, item)" title="Remove">close</i></a>
                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>						
            <div class="pull-right" style="margin-top: 15px">
              <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  