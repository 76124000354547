import { Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { ClientComponent } from './client/client.component';
import { ShowComponent as ClientShowComponent } from './client/show/show.component';
import { FollowUpComponent } from './followup/followup.component';
import { ShowComponent as FollowUpShowComponent } from './followup/show/show.component';
import { QuotationComponent } from './quotation/quotation.component';
import { ShowComponent as QuotationShowComponent } from './quotation/show/show.component';
import { SalesorderComponent } from './salesorder/salesorder.component';
import { ShowComponent as SalesorderShowComponent } from './salesorder/show/show.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ShowComponent as InvoiceShowComponent } from './invoice/show/show.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { AddComponent as ReceiptAddComponent } from './receipt/add/add.component';
import { ShowComponent as ReceiptShowComponent } from './receipt/show/show.component';
import { SalesCommissionComponent } from './sales-commission/sales-commission.component';
import { ShowComponent as SalesCommissionShowComponent } from './sales-commission/show/show.component';
import { RegionComponent } from './region/region.component';
import { ShowComponent as RegionShowComponent } from './region/show/show.component';
import { UserComponent } from './user/user.component';
import { ShowComponent as UserShowComponent } from './user/show/show.component';
import { StaffComponent } from './staff/staff.component';
import { ShowComponent as StaffShowComponent } from './staff/show/show.component';

import { ProductComponent } from './product/product.component';
import { ShowComponent as ProductShowComponent } from './product/show/show.component';
import { MeasurementComponent as ProductMeasurementComponent } from './product/measurement/measurement.component';
import { ShowComponent as ProductMeasurementShowComponent } from './product/measurement/show/show.component';
import { PartComponent } from './product/part/part.component';
import { ShowComponent as PartShowComponent } from './product/part/show/show.component';
import { CategoryComponent as ProductCategoryComponent } from './product/category/category.component';
import { ShowComponent as ProductCategoryShowComponent } from './product/category/show/show.component';

import { GalleryComponent } from './gallery/gallery.component';
import { SettingComponent } from './setting/setting.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { AuthGuard } from './auth-guard.guard';
import { GuestGuard } from './guest-guard.guard';

export const AppRoutes: Routes = [
    {
      path: '',
      redirectTo: 'login',
      pathMatch: 'full',
    }, {
      path: 'register',
      component: RegisterComponent,
	    canActivate:  [GuestGuard],
    }, {
      path: 'forgot',
      component: ForgotPasswordComponent,
	  canActivate:  [GuestGuard],
    },
    {
      path: 'reset/:code',
      component: ResetPasswordComponent,
	  canActivate:  [GuestGuard],
    }, {
      path: 'login',
      component: LoginComponent,
      canActivate:  [GuestGuard],
    }, {
      path: '',
      component: AdminLayoutComponent,
      canActivate:  [AuthGuard],
      children: [
           {
        path: 'client',
        component: ClientComponent
      }, {
        path: 'client/show',
        component: ClientShowComponent
      }, {
        path: 'dashboard',
        component: DashboardComponent
    }, {
        path: 'follow_up',
        component: FollowUpComponent
      }, {
        path: 'follow_up/show',
        component: FollowUpShowComponent
      }, {
        path: 'gallery',
        component: GalleryComponent
      }, {
        path: 'invoice',
        component: InvoiceComponent
      }, {
        path: 'invoice/show',
        component: InvoiceShowComponent
      }, {
        path: 'product',
        component: ProductComponent
      }, {
        path: 'product/show',
        component: ProductShowComponent
      }, {
        path: 'product/category',
        component: ProductCategoryComponent
      }, {
        path: 'product/category/show',
        component: ProductCategoryShowComponent
      }, {
        path: 'product/measurement',
        component: ProductMeasurementComponent
      }, {
        path: 'product/measurement/show',
        component: ProductMeasurementShowComponent
      }, {
        path: 'product/part',
        component: PartComponent
      }, {
        path: 'product/part/show',
        component: PartShowComponent
      }, {
        path: 'quotation',
        component: QuotationComponent
      }, {
        path: 'quotation/show',
        component: QuotationShowComponent
      }, {
        path: 'receipt',
        component: ReceiptComponent
      }, {
        path: 'receipt/show',
        component: ReceiptShowComponent
      }, {
        path: 'receipt/add',
        component: ReceiptAddComponent
      }, {
        path: 'region',
        component: RegionComponent
      }, {
        path: 'region/show',
        component: RegionShowComponent
      }, {
        path: 'sales_order',
        component: SalesorderComponent
      }, {
        path: 'sales_order/show',
        component: SalesorderShowComponent
      }, {
        path: 'staff',
        component: StaffComponent
      }, {
        path: 'staff/show',
        component: StaffShowComponent
      }, {
        path: 'user',
        component: UserComponent
      }, {
        path: 'user/show',
        component: UserShowComponent
      }, {
        path: 'sales_commission',
        component: SalesCommissionComponent
      }, {
        path: 'sales_commission/show',
        component: SalesCommissionShowComponent
      },{
        path: 'setting',
        component: SettingComponent
      }, {
		path: '**',
	    component: PageNotFoundComponent
	  }
  ]}, //{
      //path: '',
      //component: AuthLayoutComponent,
      //children: [{
        //path: 'pages',
        //loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
      //}]
    //}
];
