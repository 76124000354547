<div class="main-content" style="margin-top: 0px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="card">
                <div class="card-header card-header-rose card-header-icon">
                  <div class="card-icon">
                    <i class="material-icons">mail_outline</i>
                  </div>
                  <h4 class="card-title">{{ key ? 'Edit Category' : 'Add Category' }}</h4>
                </div>
                <div class="card-body">
                    <div class="row" style="margin: 0 20px 0 0">
                      <div class="col-sm-12">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Name" maxlength="255" type="text" formControlName="name" />						
                              <mat-error *ngIf="form.controls.name.hasError('required')">Name is required.</mat-error>
                          </mat-form-field>
                      </div>
                   </div>
                   
                   <div class="row" style="margin: 20px 0">
                      <div class="col-md-4 col-sm-4">
                          <p>Picture</p>
                          <div class="fileinput text-center {{ image ? 'fileinput-exists' : 'fileinput-new' }}" data-provides="fileinput">
                              <div class="fileinput-new thumbnail">
                                  <img src="./assets/img/image_placeholder.jpg" alt="...">
                              </div>
                              <div class="fileinput-preview fileinput-exists thumbnail">
                                  <img *ngIf="image" src="{{ image }}" alt="Uploaded Image" />
                              </div>
                              <div>
                                  <span class="btn btn-rose btn-round btn-file">
                                      <span class="fileinput-new">Select image</span>
                                      <span class="fileinput-exists">Change</span>
                                      <input type="file" (change)="onFileChange($event)" accept="image/*" />
                                  </span>
                                  <a href="#pablo" class="btn btn-danger btn-round fileinput-exists" data-dismiss="fileinput" (click)="remove()"><i class="fa fa-times"></i> Remove</a>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
                <div class="card-footer ml-auto" >
                  <button mat-raised-button class="btn btn-fill btn-rose" [disabled]="!(form.valid && !submitting)">{{ key ? 
                      (this.submitting ? 'Updating' : 'Update') : (this.submitting ? 'Submitting' : 'Submit') }}</button>
                </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  