<div class="main-content" style="margin-top: 0px">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <h3 style="margin-bottom: 30px">Product List</h3>
      </div>
      <div class="col-lg-4 ml-auto" >
        <a class="btn btn-fill btn-rose" style="float: right;" (click)="add()">Add New</a>
      </div>
          <div class="col-md-12">
              <div class="card">
                <div class="card-header card-header-primary card-header-icon">
                  <div class="card-icon">
                    <i class="material-icons">assignment</i>
                  </div>
                    <h4 class="card-title">Product List</h4>
                </div>
                  <div class="card-body">
                      <div class="toolbar">
            <div class="row form-group">
              <div class="col-sm-4">
                <select class="form-control" [(ngModel)]="category_id" (ngModelChange)="filter()">
                  <option value="">All category</option>
                  <option *ngFor="let category of categoryList" [value]="category.id">{{ category.name }}</option>
                </select>
              </div>
              <div class="offset-sm-4 col-md-4">
                <input name="no" type="text" class="form-control" [(ngModel)]="term" (ngModelChange)="filter()" placeholder="Search Keyword..." >
              </div>
            </div>
                      </div>
                      <div class="material-datatables">
                        <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                              <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Parts</th>
                                    <th>Activation</th>
                                    <th>Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngIf="!productList">
                  <td colspan="7" class="text-center">Loading product...</td>
                </tr>
                <tr *ngIf="productList && productList.length == 0">
                  <td colspan="7" class="text-center">No available product.</td>
                </tr>
                <tr *ngIf="haveFilter() && filteredList.length == 0">
                  <td colspan="7" class="text-center">No matched product.</td>
                </tr>
                                  <tr *ngFor="let item of filteredList | paginate: { itemsPerPage: 6, currentPage: p }; let i = index; trackBy: trackByFn">
                                      <td>{{ item.name }}</td>
                                      <td style="white-space: pre-line;">{{ item.description }}</td>
                                      <td>
                    <label *ngIf="item.parts == 0">No any part.</label>
                    <label *ngIf="!loadedParts[item.id]"><u style="cursor: pointer;" (click)="viewPart(item)">{{ item.viewing ? "Loading..." : "View Part" }}</u></label>
                    <div *ngFor="let part of groupPart(item.id)" class="row" style="white-space: pre-wrap;">
                      <div class="col-12">{{ part.level + part.name }}</div>
                    </div>
                  </td>
                                      <td>
                                        <div class="togglebutton">
                                          <label>
                                            <input type="checkbox" [ngModel]="!item.deleted_at" (ngModelChange)="updateStatus(item, $event)" />
                                            <span class="toggle"></span>
                                          </label>
                                        </div>
                                      </td>
                                      <td>
                                        <a class="btn btn-link btn-info btn-just-icon like"><i class="material-icons" (click)="edit(i, item)" title="Edit">edit</i></a>
                                        <a class="btn btn-link btn-primary btn-just-icon copy"><i [class.disabled]="item.copying" class="material-icons" (click)="copy(i, item)" title="Copy Part">copy_all</i></a>
                                        <a class="btn btn-link btn-warning btn-just-icon transfer"><i [class.disabled]="item.transfering" class="material-icons" (click)="transfer(i, item)" title="Transfer Part to Other">shuffle</i></a>
                                        <a class="btn btn-link btn-danger btn-just-icon remove"><i [class.disabled]="item.deleting" class="material-icons" (click)="remove(i, item)" title="Remove">close</i></a>
                                      
                    <a class="btn btn-link btn-success btn-just-icon more"><i class="material-icons" (click)="sub(item)" title="Product Parts">menu</i></a>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
          <div class="pull-right" style="margin-top: 15px">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
          </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
