<div class="main-content" style="margin-top: 0px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="card ">
                <div class="card-header card-header-rose card-header-icon">
                  <div class="card-icon">
                    <i class="material-icons">mail_outline</i>
                  </div>
                  <h4 class="card-title">{{ key ? 'Edit Form' : 'Input Form' }}</h4>
                </div>
                <div class="card-body ">
                    <div class="row" style="margin: 0 20px 0 0">
                      <div class="col-sm-8">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Name" maxlength="255" type="text" formControlName="name" />						
                              <mat-error *ngIf="form.controls.name.hasError('required')">Name is required.</mat-error>
                          </mat-form-field>
                      </div>
                      
                      <div class="col-sm-4">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Code" maxlength="255" type="text" formControlName="code" />						
                              <mat-error *ngIf="form.controls.code.hasError('required')">Code is required.</mat-error>
                          </mat-form-field>
                      </div>
                   </div>
                   
                   <div class="row" style="margin: 0 20px 0 0">
                      <div class="col-sm-6">
                          <mat-form-field class="example-full-width">
                              <mat-select formControlName="category_id">
                                  <mat-option value="">No category</mat-option>
                                  <mat-option *ngFor="let category of categoryList" [value]="category.id">{{ category.name }}</mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>
                   </div>
                   
                   <div class="row" style="margin: 20px 0">
                      <div class="col-sm-12">
                          <mat-form-field class="example-full-width">
                              <textarea matInput placeholder="Description" maxlength="8000" rows="4" formControlName="description"></textarea>
                              <mat-error *ngIf="form.controls.description.hasError('required')">Description is required.</mat-error>
                          </mat-form-field>
                      </div>
                   </div>
                    
                   <div class="row" style="margin: 20px 0">
                      <div class="col-md-4 col-sm-4">
                          <p>Picture</p>
                          <div class="fileinput text-center {{ image ? 'fileinput-exists' : 'fileinput-new' }}" data-provides="fileinput">
                              <div class="fileinput-new thumbnail">
                                  <img src="./assets/img/image_placeholder.jpg" alt="...">
                              </div>
                              <div class="fileinput-preview fileinput-exists thumbnail">
                                  <img *ngIf="image" src="{{ image }}" alt="Uploaded Image" />
                              </div>
                              <div>
                                  <span class="btn btn-rose btn-round btn-file">
                                      <span class="fileinput-new">Select image</span>
                                      <span class="fileinput-exists">Change</span>
                                      <input type="file" (change)="onFileChange($event)" accept="image/*" />
                                  </span>
                                  <a href="#pablo" class="btn btn-danger btn-round fileinput-exists" data-dismiss="fileinput" (click)="remove()"><i class="fa fa-times"></i> Remove</a>
                              </div>
                          </div>
                      </div>
                                          
                      <div class="col-md-8 col-sm-8">
                          <p>Have Discount(s) on this Product</p>
                      
                          <input type="checkbox" formControlName="have_discount" value="true" />
                   
                          <ng-container *ngIf="form.controls.have_discount.value">
                              <ng-container formArrayName="discounts" *ngFor="let price of form.controls.discounts.controls; let i = index">
                                  <div class="row" [formGroupName]="i">
                                      <input type="hidden" formControlName="id" />
                                      <div class="col-4">
                                          <mat-form-field class="example-full-width">
                                              <input matInput maxlength="255" formControlName="name" placeholder="Discount Name" />
                                              <mat-error *ngIf="price.controls.name.hasError('required')">Discount Name is required.</mat-error>
                                          </mat-form-field>
                                      </div>
                                      <div class="col-3">
                                          <mat-form-field class="example-full-width">
                                              <input matInput maxlength="255" formControlName="discount" placeholder="Maximum Discount (%)" />
                                              <mat-error *ngIf="price.controls.discount.hasError('required')">Maximum Discount (%) is required.</mat-error>
                                          </mat-form-field>
                                      </div>
                                      <div class="col-3">
                                          <mat-form-field class="example-full-width">
                                              <input matInput maxlength="255" formControlName="addon" placeholder="Maximum Add On (%)" />
                                              <mat-error *ngIf="price.controls.addon.hasError('required')">Maximum Add On (%) is required.</mat-error>
                                          </mat-form-field>
                                      </div>									
                                      <div class="col-2">
                                          <a *ngIf="form.controls.discounts.controls.length > 1" class="btn btn-link btn-danger btn-just-icon remove"><i class="material-icons" (click)="removeDiscount(i)" title="Remove">close</i></a>
                                      </div>
                                  </div>
                              </ng-container>
                                      
                              <div class="row" style="margin: 20px 0">
                                  <button type="button" (click)="addDiscount()" class="btn btn-fill btn-rose">Add Discount</button>
                              </div>
                          </ng-container>
                      </div>
                  </div>
                </div>
                <div class="card-footer ml-auto" >
                  <button mat-raised-button class="btn btn-fill btn-rose" [disabled]="!(form.valid && !submitting)">{{ key ? 
                      (this.submitting ? 'Updating' : 'Update') : (this.submitting ? 'Submitting' : 'Submit') }}</button>
                </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  