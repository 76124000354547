<div class="main-content" style="margin-top: 0px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="card ">
                <div class="card-header card-header-rose card-header-icon">
                  <div class="card-icon">
                    <i class="material-icons">mail_outline</i>
                  </div>
                  <h4 class="card-title">{{ key ? 'Edit Admin' : 'Input Admin' }}</h4>
                </div>
                <div class="card-body ">
                    <div class="row" style="margin: 0 20px 0 0">
                      <div class="col-sm-6">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Name" maxlength="255" type="text" formControlName="name" />						
                              <mat-error *ngIf="form.controls.name.hasError('required')">Name is required.</mat-error>
                          </mat-form-field>
                      </div>
                      
                      <div class="col-sm-6">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Email Address" maxlength="255" type="text" formControlName="email" />						
                              <mat-error *ngIf="form.controls.email.hasError('required')">Email Address is required.</mat-error>
                              <mat-error *ngIf="form.controls.email.hasError('email')">Please enter in email format something@domain.com.</mat-error>
                              <mat-error *ngIf="form.controls.email.errors?.custom; let custom">{{ custom }}</mat-error>
                          </mat-form-field>
                      </div>
                   </div>
                  
                   <div class="row" style="margin: 0 20px 0 0">
                      <div class="col-sm-4">
                          <mat-form-field *ngIf="form.get('role_id'); let control" class="example-full-width">
                              <mat-select formControlName="role_id">
                                  <mat-option value="">No Admin Role</mat-option>
                                  <mat-option *ngFor="let data of roleList" [value]="data.id">{{ data.name }}</mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>
  
                      <div class="col-sm-4">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Password" maxlength="255" [type]="hide ? 'password' : 'text'" formControlName="password" />						
                              <i mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                              </i>
                              <mat-error *ngIf="form.controls.password.hasError('required')">Password is required.</mat-error>
                              <mat-error *ngIf="form.controls.password.errors?.custom; let custom">{{ custom }}</mat-error>
                          </mat-form-field>
                      </div>
                      
                      <div class="col-sm-4">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Password Confirmation" maxlength="255" [type]="hideConfirmation ? 'password' : 'text'" formControlName="password_confirmation" />						
                              <i mat-icon-button matSuffix (click)="hideConfirmation = !hideConfirmation" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                  <mat-icon>{{hideConfirmation ? 'visibility_off' : 'visibility'}}</mat-icon>
                              </i>
                              <mat-error *ngIf="form.controls.password_confirmation.hasError('required')">Password Confirmation is required.</mat-error>
                          </mat-form-field>
                      </div>
                   </div>
                
                   <div class="row" style="margin: 0 20px 0 0">
                      <div class="col-sm-12">
                          <ng-container *ngFor="let region of regionList" formGroupName="regions">
                              <mat-checkbox [formControlName]="region.id">{{ region.name }}</mat-checkbox>
                              <br />
                          </ng-container>
                      </div>
                   </div>
                </div>
                  
                <div class="card-footer ml-auto" >
                  <button mat-raised-button class="btn btn-fill btn-rose" [disabled]="!(form.valid && !submitting)">{{ key ? 
                      (this.submitting ? 'Updating' : 'Update') : (this.submitting ? 'Submitting' : 'Submit') }}</button>
                </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  