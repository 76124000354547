<div class="main-content" style="margin-top: 0px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
              <div class="card">
                <div class="card-header card-header-rose card-header-icon">
                  <div class="card-icon">
                    <i class="material-icons">mail_outline</i>
                  </div>
                  <h4 class="card-title">{{ key ? ('Update ' + title) : ('Add ' + title) }}</h4>
                </div>
                <div class="card-body">
                  <div class="row" style="margin: 0 20px 0 0">
                      <div class="col-sm-6 v-center">
                          <mat-form-field *ngIf="form.get('name'); let control" class="example-full-width">
                              <input matInput placeholder="Receipt Name" maxlength="255" type="text" formControlName="name" />
                              <mat-error *ngIf="control.hasError('required')">Receipt name is required.</mat-error>
                          </mat-form-field>
                      </div>
                      
                      <div class="col-sm-6 v-center">
                          <mat-form-field *ngIf="form.get('receipt_date'); let control" class="example-full-width">
                              <input matInput maxlength="255" type="datetime-local" formControlName="receipt_date" />
                              <mat-error *ngIf="control.hasError('required')">Receipt date is required.</mat-error>
                          </mat-form-field>
                      </div>
                  </div>
                  
                  <div class="row" style="margin: 0 20px 0 0">
                      <div class="col-sm-4" [class.v-center]="loading || cUID || key">
                          <mat-label *ngIf="loading else ready">{{ labelText }}</mat-label>
                                  
                          <ng-template #ready>
                              <ng-container *ngIf="cUID || key else selection">
                                  <input *ngIf="form.get('client_id')" type="hidden" formControlName="client_id" />
                                  <mat-label>{{ client ? (client.company_name + (client.person ? " (" + client.person + ")" : "")) : "Client not available" }}</mat-label>
                              </ng-container>
                              
                              <ng-template #selection>
                                  <mat-form-field *ngIf="form.get('client_id'); let control" class="example-full-width">
                                      <mat-select formControlName="client_id" (ngModelChange)="clientChange()">
                                          <mat-option value="">Select Client</mat-option>
                                          <mat-option *ngFor="let data of clientList" [value]="data.id">{{ data.company_name + (data.person ? " (" + data.person + ")" : "") }}</mat-option>
                                      </mat-select>
                                      <mat-error *ngIf="control.hasError('required')">Client is required.</mat-error>
                                  </mat-form-field>
                              </ng-template>
                          </ng-template>
                      </div>
                      
                      <div class="col-sm-4">
                          <mat-form-field *ngIf="form.get('cheque_no'); let control" class="example-full-width">
                              <input matInput placeholder="Cheque No/Transaction Ref No" maxlength="255" type="text" formControlName="cheque_no" />
                              <mat-error *ngIf="control.hasError('required')">Cheque No is required.</mat-error>
                          </mat-form-field>
                      </div>
                      
                      <div class="col-sm-4">
                          <mat-form-field *ngIf="form.get('region_id'); let control" class="example-full-width">
                              <mat-select formControlName="region_id">
                                  <mat-option value="">Select Sales Region</mat-option>
                                  <mat-option *ngFor="let data of regionList" [value]="data.id">{{ data.name }}</mat-option>
                              </mat-select>
                              <mat-error *ngIf="control.hasError('required')">Sales Region is required.</mat-error>
                          </mat-form-field>
                      </div>
                  </div>
                              
                  <div formArrayName="rois" class="row" style="margin: 0 20px 0 0">
                      <ng-container *ngFor="let control of ROIArray().controls; let i = index" [formGroupName]="i">
                          <div *ngIf="control.get('invoice_id'); let invoiceId" class="col-7">
                              <input type="hidden" formControlName="invoice_id" />
                              <mat-label for="roi_{{ i }}">{{ getInvoiceData(invoiceId.value) }}</mat-label>
                          </div>
                          <div class="col-4">
                              <mat-form-field *ngIf="control.get('amount'); let amountControl" class="example-full-width">
                                  <input id="roi_{{ i }}" matInput placeholder="Amount to Pay" type="number" formControlName="amount" />
                                  <mat-error *ngIf="amountControl.hasError('required')">Amount is required.</mat-error>
                                  <mat-error *ngIf="amountControl.hasError('max')">Amount cannot be greater than {{ amountControl.errors.max.max | currency: "RM " }}</mat-error>
                              </mat-form-field>
                          </div>
                          <div class="col-1 text-right">
                              <button type="button" class="btn btn-link btn-danger btn-just-icon remove" (click)="removeInvoice(i)">
                                  <i class="material-icons">close</i>
                              </button>
                          </div>
                      </ng-container>
                      <button type="button" class="btn btn-fill btn-rose" (click)="addInvoice()" [disabled]="!(invoiceList?.length > 0)">{{
                          form.get('client_id').value ? 
                          (invoiceList ? (invoiceList.length > 0 ? "Add invoice to receipt" : "No invoice on selected client") : "Loading invoice") :
                          "Please select client"
                      }}</button>
                  </div>
                
                    <div class="row" style="margin: 0 20px 0 0">
                      <div class="col-sm-12">
                          <mat-form-field *ngIf="form.get('remark'); let control" class="example-full-width">
                              <textarea matInput placeholder="Remark" rows="3" formControlName="remark"></textarea>
                          </mat-form-field>
                      </div>
                  </div>
                </div>
                <div class="card-footer ml-auto" >
                  <button mat-raised-button class="btn btn-fill btn-rose" [disabled]="!(form.valid && !submitting)">{{ key ? 
                      (submitting ? 'Updating' : 'Update') : 
                      (submitting ? 'Submitting' : 'Submit') }}</button>
                </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>