import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from './../../shared/api.service';
import { LocalizeService } from './../../shared/localize.service';
import Swal from 'sweetalert2';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { merge } from 'rxjs';

@Component({
  selector: 'app-cw-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit {
  form = new FormGroup({
	company_name: new FormControl('', [Validators.required]),
	person: new FormControl('', []),
	email: new FormControl('', [Validators.email]),
	phone: new FormControl('', [Validators.required]),
	address: new FormControl('', []),
	area: new FormControl('', []),
	state: new FormControl('', []),
	deposit_percentage: new FormControl('', []),
	id: new FormControl('', []),
  });

  key;
  submitting;

  file;
  image;

  fileList = [];

  translations = {};
  hide;
  hideConfirmation;

  stateList = [
	  "Selangor",
	  "Kuala Lumpur",
	  "Johor",
	  "Perak",
	  "Penang",
	  "Negeri Sembilan",
	  "Melaka",
	  "Pahang",
	  "Sabah",
	  "Sarawak",
	  "Kedah",
	  "Putrajaya",
	  "Kelantan",
	  "Terengganu",
	  "Perlis",
	  "Labuan"
  ];
  
  constructor(  
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
	private localizeService: LocalizeService) {
  }

  ngOnInit(): void {
	this.translations = this.localizeService.getTranslation('client');
	
	this.key = this.aroute.snapshot.params['uid'];
	
	this.initFormData();
  }
  
  async initFormData() {
	if(this.key) {			
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.CLIENT_GET, [this.key])
		).then((data : any) => {
			this.form.patchValue({
				company_name: data.company_name,
				person: data.person,
				email: data.email,
				phone: data.phone,
				address: data.address,
				area: data.area,
				state: data.state,
				deposit_percentage: data.deposit_percentage,
			});
			
			this.image = data.image ? (APIService.STORAGE + data.image) : null;			
		}, err => {
			Swal.fire("Error on Client Show", this.apiService.handleStatusException(err), 'error');
		});
	}
  }

  onSubmit() {
    if(this.submitting) {
		return;
	}

	if(this.form.valid) {
		this.submitting = true;
		var data = this.form.value;

		if(this.key) {
			data.id = this.key;
		}

		this.apiService.post(
			this.apiService.getParameterizedURL(this.key ? APIService.CLIENT_UPDATE : APIService.CLIENT_CREATE), data
		).then((res : any) => {
			this.key = res.id;
			
			var fileProcessList = [];
			if(this.file) {
				var formData : FormData = new FormData();
				formData.append('image', this.file, this.file.name);
				formData.append('id', this.key);
				
				fileProcessList.push(
					this.apiService.post(
						this.apiService.getParameterizedURL(APIService.CLIENT_UPLOAD), formData, {
							"Content-Type":false
						}
					).then((data : any) => {
						data.image = data;
					})
				);
			}
		
			merge(fileProcessList).toPromise().then(() => {			
				this.router.navigate(['client']).then(() => {
					this.submitting = false;
				});
			}, err => {
				this.submitting = false;
				Swal.fire("Error on Client Photo", this.apiService.handleStatusException(err), 'error');		
			});
		}, err => {
			this.submitting = false;
			Swal.fire("Error on Client Submit", this.apiService.handleStatusException(err), 'error');
		});
	}
  }

  selectAll(type, value) {
	if(!this.form.get(type)) {
		return;
	}

	var result = [];
	
	for(var i = 0; i < this.form.get(type).value.length; i++) {
		result[i] = value;
	}

	this.form.get(type).patchValue(result);
  }

  // dropzone
  onRemove(i) {
	this.fileList.splice(this.fileList.findIndex(i), 1);
  }

  onSelect(evt) {
	this.fileList = evt.addedFiles;
  }

  // profile
  onFileChange(evt) {
	this.file = evt.target.files.item(0);
  }

  remove() {
	this.image = null;
  }
}
