import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from './../../shared/api.service';
import Swal from 'sweetalert2';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit {
  form = new FormGroup({
	name: new FormControl('', [Validators.required]),
	short_name: new FormControl('', [Validators.required]),
	commission_percentage: new FormControl('', [Validators.required, Validators.min(-999.99), Validators.max(999.99)]),
	id: new FormControl('', []),
  });

  key;
  submitting;
  
  title = "Sales Region";
  formControls = [
	[
		{ field: 'name', placeholder: "Name", type: "text", class_name: "col-9" },
		{ field: 'short_name', placeholder: "Short Name", type: "text", class_name: "col-3" },
		{ field: 'commission_percentage', placeholder: "Commission Percentage (%)", type: "number" },
	]
  ];
  
  constructor(  
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService) {
  }

  ngOnInit(): void {
	this.key = this.aroute.snapshot.params['uid'];
	
	this.initFormData();
  }
  
  async initFormData() {
	if(this.key) {
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.SALES_REGION_GET, [this.key])
		).then((data : any) => {
			this.form.patchValue({
				name: data.name,
				short_name: data.short_name,
				commission_percentage: (data.commission_percentage || 0).toFixed(2),
			});
		}, err => {
			Swal.fire(this.title + " Show", this.apiService.handleStatusException(err), 'error');
		});
	} else {
	}
  }

  onFormSubmit() {
	var data = this.form.value;

	if(this.key) {
		data.id = this.key;
	}

	this.apiService.post(
		this.apiService.getParameterizedURL(this.key ? APIService.SALES_REGION_UPDATE : APIService.SALES_REGION_CREATE), data
	).then((res : any) => {
		this.router.navigate(['region']).then(() => {
			this.submitting = false;
		});
	} , err => {
		this.submitting = false;
		Swal.fire(this.title + (this.key ? " Submit" : " Update"), this.apiService.handleStatusException(err), 'error');
	});
  }
}