import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-standard-show',
  templateUrl: './standard-show.component.html',
  styleUrls: ['./standard-show.component.css']
})
export class StandardShowComponent {
	@Input() key;
	@Input() pageAddName;
	@Input() pageEditName;
	@Input() addText;
	@Input() addingText;
	@Input() editText;
	@Input() editingText;
	@Input() icon;
	
	@Input() form;
	@Input() formControls : Array<object>;
	@Output() formSubmit: EventEmitter<any> = new EventEmitter();
	@Input() submitting;
	
	loadingImg = 'assets/img/dummy-image.png';
	
	onFormSubmit() {
		if(this.submitting) {
			return;
		}
		
		if(this.form.valid) {
			this.submitting = true;
			this.formSubmit.emit();
		}
	}
	
	capitalize(text) {
		var capitalizedText = "";
		
		if(text.length > 0) {
			capitalizedText = text[0].toUpperCase() + text.substr(1, text.length - 1);
		}
		
		return capitalizedText;
	}
}
