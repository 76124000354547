<div style="background: #fff; background-size: cover; background-position: top center;min-height: 100vh">
	<div class="container">
	  <div class="row">
		<div class="col-10 col-sm-8 ml-auto mr-auto">
			<form *ngIf="code else noCode" #f="ngForm" (ngSubmit)="onSubmit(f)">
			  <img src="./assets/img/logo.jpg" class="login-logo">
			  <div class="row">
				<div class="col-sm-12">
					<h3 class="login-title">Reset Password</h3>
				</div>
				<div class="col-sm-12">
                  <p class="login-label">Email Address</p>
                  <input class="input" [class.is-invalid]="errors['email']" type="email" name="email" placeholder="Email Address" ngModel />
				  
				  <div class="invalid-feedback" role="alert" *ngIf="errors['email']">
					{{ errors['email'] }}
				  </div>
                </div>
				<div class="col-sm-12">
					<p class="login-label">New Password</p>
					<input class="input" [class.is-invalid]="errors['password']" type="password" name="password" placeholder="New Password" ngModel />
			
				    <div class="invalid-feedback" role="alert" *ngIf="errors['password']">
					  {{ errors['password'] }}
				    </div>
				</div>
				<div class="col-sm-12">
					<p class="login-label">New Password Confirmation</p>
					<input class="input" [class.is-invalid]="errors['password_confirmation']" type="password" name="password_confirmation" placeholder="New Password Confirmation" ngModel />
			
				    <div class="invalid-feedback" role="alert" *ngIf="errors['password_confirmation']">
					  {{ errors['password_confirmation'] }}
				    </div>
				</div>
				<div class="col-sm-4 ml-auto mr-auto">
					<button class="btn-signin">{{ submitting ? 'Processing' : 'Change My Password' }}</button>
				</div>
			  </div>
			</form>
			<ng-template #noCode>
			  <img src="./assets/img/logo.jpg" class="login-logo">
			  <div class="row">
				<div class="col-sm-12">
					<h3 class="login-title">Reset Password</h3>
				</div>
				<div class="col-sm-12">
					<i>Your Link is invalid, make sure it is from our system email.</i>
				</div>
			  </div>
			</ng-template>
		</div>
	  </div>
	</div>
</div>