import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from './../../shared/api.service';
import { LocalizeService } from './../../shared/localize.service';
import Swal from 'sweetalert2';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { merge } from 'rxjs';

@Component({
  selector: 'app-cw-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit {
  form = new FormGroup({
	name: new FormControl('', [Validators.required]),
	email: new FormControl('', [Validators.required, Validators.email]),
	password: new FormControl('', []),
	password_confirmation: new FormControl('', []),
	regions: new FormGroup({}),
	id: new FormControl('', []),
  });

  key;
  submitting;

  hide = true;
  hideConfirmation = true;
  
  regionList = [];
  
  constructor(  
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService) {
  }

  ngOnInit(): void {	
	this.key = this.aroute.snapshot.params['uid'];
	
	this.initRegion();
  }
  
  initRegion() {
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.SALES_REGION_LISTING)
	).then((regionList : any) => {
		var regionGroup = this.form.controls.regions as FormGroup;
		
		regionList.forEach(region => {
			regionGroup.addControl(region.id, new FormControl(""));
		});
		
		this.regionList = regionList;
	
		this.initFormData();
	}, err => {	
		this.initFormData();
	});	
  }
  
  async initFormData() {
	if(this.key) {			
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.STAFF_GET, [this.key])
		).then((data : any) => {
			var regions = {};
			
			data.admin_regions.forEach(aRegion => {
				regions[aRegion.region_id] = true;
			});
			this.form.patchValue({
				name: data.name,
				email: data.email,
				regions: regions
			});		
		}, err => {
			Swal.fire("Error on Admin Show", this.apiService.handleStatusException(err), 'error');
		});
	} else {
	  this.form.controls.password.setValidators([Validators.required]);
	  this.form.controls.password_confirmation.setValidators([Validators.required]);
	  this.form.controls.password.updateValueAndValidity();
	  this.form.controls.password_confirmation.updateValueAndValidity();
	}
  }

  onSubmit() {
    if(this.submitting) {
		return;
	}

	if(this.form.valid) {
		this.submitting = true;
		var data = this.form.value;

		if(this.key) {
			data.id = this.key;
		}

		this.apiService.post(
			this.apiService.getParameterizedURL(this.key ? APIService.STAFF_UPDATE : APIService.STAFF_CREATE), data
		).then(() => {						
			this.router.navigate(['staff']).then(() => {
				this.submitting = false;
			});
		}, err => {
			this.submitting = false;
			if(err.status == 422) {
				Object.keys(err.error.errors).forEach(key => {
					this.form.get(key)?.setErrors({ custom: err.error.errors[key].join("\n") });
				});
				
				Swal.fire("Admin Submit", err.error.message, 'warning');
			} else {
				Swal.fire("Admin Submit", this.apiService.handleStatusException(err), 'error');
			}
		});
	}
  }
}
