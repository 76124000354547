import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from './../../../shared/api.service';
import Swal from 'sweetalert2';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { merge } from 'rxjs';
import { moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-cw-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit {
  form = new FormGroup({
	name: new FormControl('', [Validators.required]),
	code: new FormControl('', []),
	description: new FormControl('', []),
	allow_empty: new FormControl(false, []),
	allow_multi: new FormControl(false, []),
	allow_add: new FormControl(false, []),
	is_group: new FormControl(false, []),
	have_price: new FormControl(false, []),
	prices: new FormArray([
	// new FormGroup({
		// id: new FormControl('', []),
		// name: new FormControl('', []),
		// specifications: new FormArray([new FormGroup({
			// region_amount: new FormArray([]),			
			// measurement_id: new FormControl('', []),
			// discount_id: new FormControl('', []),
			// costing_percentage: new FormControl('', [Validators.required, Validators.min(-999.99), Validators.max(999.99)]),
		// })]),
	// })
	]),
  costing_percentage: new FormControl('', []),
  id: new FormControl('', []),
  });

  key;
  submitting;

  file;
  image;

  fileList = [];

  translations = {};
  
  mId;
  mName;
  pId;
  pName;
  parentId;
  parentName;
  
  measurementList = [];  
  discountList = [];  
  regionList = [];
  
  dashboardRegion;
  
  constructor(
	private cd: ChangeDetectorRef,
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService) {
  }

  ngOnInit(): void {
	this.key = this.aroute.snapshot.params['uid'];
	this.mId = this.aroute.snapshot.params['m_uid'] || '';
	this.mName = this.aroute.snapshot.params['m_name'] || '';
	this.parentId = this.aroute.snapshot.params['parent_uid'] ?? '';
	this.parentName = this.aroute.snapshot.params['parent_name'] ?? '';
	this.pId = this.aroute.snapshot.params['p_uid'] || '';
	this.pName = this.aroute.snapshot.params['p_name'] || '';
	
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.PRODUCT_MEASUREMENT_LISTING)
	).then((measurementList : any) => {
		this.measurementList = measurementList;
	}, err => {
		//
	});
	
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.SALES_REGION_LISTING)
	).then((regionList : any) => {
		this.regionList = regionList;
		
		this.initData();
	}, err => {
		Swal.fire("Sales Region", "Failed to load sales region, please refresh page.", "error");
	});
	
	this.dashboardRegion = localStorage.getItem("region");
  }
  
  initData() {	  
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.PRODUCT_DISCOUNT_LISTING), {
			id: this.pId
		}
	).then((discountList : any) => {
		this.discountList = discountList;
		
		this.initFormData();
	}, err => {
		//
		this.initFormData();
	});
  }

  async initFormData() {
	if(this.key) {
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.PRODUCT_PART_GET, [this.key])
		).then((data : any) => {
			this.form.patchValue({
				name: data.name,
				description: data.description,
				code: data.code,
				costing_percentage: (data.costing_percentage || 0).toFixed(2),
				have_price: data.specifications.length > 0,
				is_group: data.is_group,
				allow_empty: data.allow_empty,
				allow_multi: data.allow_multi,
				allow_add: data.allow_add,
			});
			
			data.specifications.forEach((specification : any) => {
				this.addSpecification(specification.id, specification.name, specification.prices);
			});
			
			this.image = data.image ? (APIService.STORAGE + data.image) : null;		
		}, err => {
			Swal.fire("Error on Product Show", this.apiService.handleStatusException(err), 'error');
		});
	}
  }

  onSubmit() {
    if(this.submitting) {
		return;
	}

	if(this.form.valid) {
		this.submitting = true;
		let data = this.form.value;

		if(this.key) {
			data.id = this.key;
		}

		if(this.mId) {
			//data.parent_id = this.mId;
		}

		//data.product_id = this.pId;

		this.apiService.post(
			this.apiService.getParameterizedURL(this.key ? APIService.PRODUCT_PART_UPDATE : APIService.PRODUCT_PART_CREATE), data
		).then((res : any) => {
			let key = res.id;
			
			let fileProcessList = [];
			if(this.file) {
				let formData : FormData = new FormData();
				formData.append('image', this.file, this.file.name);
				formData.append('id', key);
				
				fileProcessList.push(
					this.apiService.post(
						this.apiService.getParameterizedURL(APIService.PRODUCT_PART_UPLOAD), formData, {
							"Content-Type":false
						}
					).then((data : any) => {
						data.image = data;
					})
				);
			}

			merge(fileProcessList).toPromise().then(() => {
				this.router.navigate(['product/part', { uid: this.mId, name: this.mName
					, parent_uid: this.parentId, parent_name: this.parentName
					, p_uid: this.pId, p_name: this.pName }]).then(() => {
					this.submitting = false;
				});
			}, err => {
				this.submitting = false;
				Swal.fire("Error on Product Photo", this.apiService.handleStatusException(err), 'error');
			})
		} , err => {
			this.submitting = false;
			Swal.fire("Error on Product Submit", this.apiService.handleStatusException(err), 'error');
		});
	}
  }

  selectAll(type, value) {
	if(!this.form.get(type)) {
		return;
	}

	let result = [];
	
	for(let i = 0; i < this.form.get(type).value.length; i++) {
		result[i] = value;
	}

	this.form.get(type).patchValue(result);
  }

  // dropzone
  onRemove(i) {
	this.fileList.splice(this.fileList.findIndex(i), 1);
  }

  onSelect(evt) {
	this.fileList = evt.addedFiles;
  }

  // profile
  onFileChange(evt) {
	this.file = evt.target.files.item(0);
  }

  remove() {
	this.image = null;
  }
  
  addSpecification(id = "", name = "", prices :object[] = []) {
	let priceArray = (this.form.controls.prices as FormArray);
	
	let newFormula = new FormGroup({
		id: new FormControl(id, []),
		name: new FormControl(name, [Validators.required]),
		specifications: new FormArray([]),
	});
	
	priceArray.push(newFormula);

	if(prices.length == 0) {
		this.addMeasurement(newFormula.controls.specifications);
	} else {
		this.addMeasurement(newFormula.controls.specifications, prices);
	}
  }

  removeSpecification(index) {
	let priceArray = (this.form.controls.prices as FormArray);
			
	priceArray.removeAt(index);
  }

  addMeasurement(array: any, prices = []) {
	if (prices.length > 0) {
		const groupedPrices = [];
		let count = -1, prevMeasurement = "";
		prices.forEach((price) => {
			if (prevMeasurement != price.measurement_id) {
				prevMeasurement = price.measurement_id;
				count++;
				groupedPrices[count] = [];
			}
			groupedPrices[count].push(price);
		});
		groupedPrices.forEach(prices => {
			const regionsGroup = new FormGroup({});
			this.addRegionData(regionsGroup, prices);
			array.push(new FormGroup({
				measurement_id: new FormControl(prices[0].measurement_id ? parseInt(prices[0].measurement_id) : "", []),
				regions: regionsGroup
			}));
		});
	} else {
		const regionsGroup = new FormGroup({});
		this.addRegionData(regionsGroup, []);
		array.push(new FormGroup({
			measurement_id: new FormControl("", []),
			regions: regionsGroup
		}));
	}
	
  }

  removeMeasurement(array, index, cIndex) {
	array.removeAt(index);

	if(array.length == 0) {
		this.removeSpecification(cIndex);
	} else if(index == 0) {		
        this.cd.detectChanges();
	}
  }
  
  itemTrackBy(index, item) {
	  return item.id;
  }
  
  addRegionData(group: FormGroup, prices: any[]) {
	this.regionList.forEach(region => {
		const regionPrice = prices.find(x => x.region_id == region.id);
		const regionGroup = new FormGroup({});
		regionGroup.addControl("amount", new FormControl(regionPrice?.amount !== undefined
		? regionPrice.amount : ""));
		regionGroup.addControl("discount_id", new FormControl(regionPrice?.discount?.discount_id || ""));
		regionGroup.addControl("costing_percentage", new FormControl(regionPrice?.costing_percentage !== undefined
		? regionPrice.costing_percentage : "")),
		group.addControl(region.id, regionGroup);
	});
  }
  
  changeExpand(spec) {
	  spec.expanded = !spec.expanded;
	  
	  spec.get('is_regional').value = spec.expanded;
  }
  
  getRegion(id) {
	  return this.regionList?.find(x => x.id == id);
  }
  
  onDrop(event: CdkDragDrop<string[]>) {
    if(event.previousIndex != event.currentIndex) {
		const priceArray = (this.form.controls.prices as FormArray);
		const target = priceArray.controls[event.currentIndex] as FormGroup;
		const current = priceArray.controls[event.previousIndex] as FormGroup;
		const currentId = current.controls.id.value;
		const targetId = target.controls.id.value;
		if (!targetId) {
			return;
		}
		
		moveItemInArray(priceArray.controls, event.previousIndex, event.currentIndex);
		
		// update server
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.PRODUCT_SPECIFICATION_REORDER), {
				part_id: this.key,
				id: currentId,
				target_id: targetId,
				is_add: event.currentIndex > event.previousIndex ? 1 : 0,
			}
		).then((res : any) => {
			
		}, err => {
			Swal.fire("Part Specification Re-Ordering", this.apiService.handleStatusException(err, true), 'error');
		});
	}
  }
}
