import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APIService } from './../shared/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  errors = {};
  submitting;
  
  constructor(
	private router: Router,
	private apiService: APIService) { }

  ngOnInit(): void {
  }

  onSubmit(f) {
	var data = f.value;
	
	var hasError = false;
	this.errors = [];
	
	if(!data.email) {
		this.errors['email'] = "Email Address is required.";
		hasError = true;
	}
	
	if(hasError || this.submitting) {
		return;
	}
	
	this.submitting = true;
	
	this.apiService.post(APIService.FORGOT_PASSWORD, { email: data.email })
	.then(() => {
		Swal.fire("Reset Password Success", "A reset password email will send to your email.", "success");
		
		this.router.navigate(['login']);
		this.submitting = false;
			
		f.resetForm();
	}, (err) => {
		this.submitting = false;
		
		if(err.status == 422 && err.error) {
			Object.keys(err.error.errors).forEach(errField => {
				this.errors[errField] = err.error.errors[errField][0];
			});
		} else {
			this.errors['email'] = this.apiService.handleStatusException(err, true);
		}
	});
  }
}
