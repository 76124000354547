import { Component, OnInit, OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';
import { CalendarOptions, DateSelectArg, EventClickArg } from '@fullcalendar/core';
import { APIService } from './../shared/api.service';
import { AppComponent } from './../app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import dayGridPlugin from '@fullcalendar/daygrid';

@Component({
  selector: 'app-followup',
  templateUrl: './followup.component.html',
  styleUrls: ['./followup.component.css']
})
export class FollowUpComponent implements OnInit, OnDestroy {

  filteredList = [];
  term = "";
  user_id = "";
  status = "";
  
  initialized;
  //calendarOptions : CalendarOptions;
  
  subscriber;
  params;
  
  followUpList = [];
  userList = [];
  events;
  
  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
	private appComponent: AppComponent) { }
  
  ngOnInit() {
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.USER_LISTING)
	).then((userList : any) => {
		this.userList = userList;
	}, err => {
		Swal.fire("Follow Up User Listing", this.apiService.handleStatusException(err, true), 'error');
	});
	
	this.aroute.params
	.subscribe(params => {
		this.params = params;
		this.user_id = this.params["u_uid"] || "";
		this.status = this.params["status"] || "";
		this.stopObs();
	
		this.startObs();
	});
  }
  
  calendarOptions: CalendarOptions = {
	plugins: [dayGridPlugin],
	initialView: 'dayGridMonth',
	headerToolbar: {
		left: 'title',
		center: 'dayGridMonth dayGridWeek dayGridDay',
		right: 'prev next today'
	},
	//defaultDate: today,
	selectable: true,
	//select: (arg) => this.handleSelect(arg),
	//eventClick: (arg) => this.handleEventClick(arg),
	editable: true,
	// weekends: false,
	//eventLimit: true, // allow "more" link when too many events
	// color classes: [ event-blue | event-azure | event-green | event-orange | event-red ]
	events: []
};

  initializeCalendar(events:any[]) {
	this.calendarOptions.events = events;
  }
  
  ngOnDestroy() {
	this.stopObs();
  }
  
  startObs(): void {
	this.subscriber = this.appComponent.getFollowUpsObs({ q_id: this.params['q_uid'] })
	.subscribe(followUpList => {
		this.followUpList = followUpList;
		this.filter();
	}, err => {
		Swal.fire("Follow Up Listing", this.apiService.handleStatusException(err, true), 'error');
	});
  }
  
  stopObs() {
	if(this.subscriber) {
		this.subscriber.unsubscribe();
	}
  }
  
  handleSelect(args : DateSelectArg) {
	// on select we show the Sweet Alert modal with an input
	// Swal.fire({
		// title: 'Schedule',
		// html: '<div class="form-group">' +
				// '<p> Sales Order ID:  SO1234</p>' +
				// '<p> Person In Charge: Salesman 3</p>' +
				// '<p> Installation Date: 2:00pm, 31/10/2020</p>' +
			// '</div>',
		// showCancelButton: true,
		// confirmButtonClass: 'btn btn-success',
		// cancelButtonClass: 'btn btn-danger',
		// buttonsStyling: false
	// }).then(function(result: any) {
		// let eventData;
		// const event_title = $('#input-field').val();

		// if (event_title) {
			// eventData = {
				// title: event_title,
				// start: args.startStr,
				// end: args.endStr,						
				// allDay: args.allDay
			// };
		// }
	// });
  }

  handleEventClick(arg : EventClickArg) {
    // if (confirm("Are you sure you want to delete the event " + arg.event.title + "?")) {
      // arg.event.remove();
    // }
  }
  
  add() {
    this.router.navigate(['follow_up/show', this.params]);
  }
  
  view() {
  }
  
  edit(index, item) {
    this.router.navigate(['follow_up/show', { uid: item.id }]);
  }
  
  remove(index, item) {
	  Swal.fire({
		title: "Delete Follow Up",
		text: "Do you want to delete schedule on " + this.datePipe.transform(item.date, 'medium') + " for " + 
			(item.quotation?.client?.company_name || item.quotation?.client?.person) + "?",
		icon: "warning",
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value) {
			item.deleting = true;
			
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.FOLLOW_UP_DELETE), {
					id: item.id
				}
			).then((res : any) => {
				item.deleting = false;
				this.followUpList.splice(index, 1);
			}, err => {
				item.deleting = false;
				Swal.fire("Follow Up Delete", this.apiService.handleStatusException(err, true), 'error');
			});
		}
	});
  }

  trackByFn(index, item) {
	return item.id;
  }
  
  sumDeposit() {
	  return "";
  }
  
  haveFilter() {
	return this.user_id || this.status || this.term;
  }
  
  filter() {
	if(!this.followUpList) {
	  return;
	}

	if(!this.haveFilter()) {
	  this.filteredList = this.followUpList;
	  return;
	}
	const events = [];
	const list = [];
	const now = new Date();
	const term = this.term?.toLowerCase();
	this.followUpList.forEach(followUp => {
		if (this.user_id && followUp.user_id !== this.user_id) {
			return;
		}
		if (this.status && followUp.date < now) {
			return;
		}
		if (term && followUp.address_remark.toLowerCase().indexOf(term) === -1
			&& followUp.client_remark.toLowerCase().indexOf(term) === -1
			&& followUp.installer?.name.toLowerCase().indexOf(term) === -1
			&& followUp.installer?.email.toLowerCase().indexOf(term) === -1
			&& (followUp.quotation?.client?.company_name || followUp.quotation?.client?.person).toLowerCase().indexOf(term) === -1
			&& followUp.quotation?.client?.full_address.toLowerCase().indexOf(term) === -1) {
			return;
		}
		// add to list view
		list.push(followUp);
		// add to calendar view
		events.push({
			title: followUp.quotation?.client?.company_name || followUp.quotation?.client?.person,
			date: new Date(followUp.date),
			allDay: true,
			className: 'event-azure'
		});
	});
	this.filteredList = list;
	this.initializeCalendar(events);
  }
}