import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from './../../shared/api.service';
import { AppComponent } from './../../app.component';
import Swal from 'sweetalert2';
import { moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-part',
  templateUrl: './part.component.html',
  styleUrls: ['./part.component.css']
})
export class PartComponent implements OnInit, OnDestroy {

  filteredList = [];
  partList;
  term;
  p;
  
  translations = {};
  uid;
  name;
  parentId;
  parentName;
  pId;
  pName;

  subscriber;
  
  productPartList;
  regionList = [];
  loadedParts = {};

  constructor(
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
    private appComponent: AppComponent,
    ) { }

  ngOnInit(): void {
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.SALES_REGION_LISTING)
	).then((regionList : any) => {
		this.regionList = regionList;
	}, err => {
		this.apiService.handleStatusException(err);
	});
	
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.PRODUCT_PART_DISCOUNT_LISTING)
	).then((productPartList : any) => {
		this.productPartList = productPartList;
	}, err => {
		this.apiService.handleStatusException(err);
	});
	
	this.aroute.params
	.subscribe(params => {
		this.uid = params['uid'] ?? '';
		this.name = params['name'] ?? '';
		this.parentId = params['parent_uid'] ?? '';
		this.parentName = params['parent_name'] ?? '';
		this.pId = params['p_uid'] ? params['p_uid'] : '';
		this.pName = params['p_name'] ?params['p_name'] : '';
		
		this.term = "";
		this.p = 0;		
		this.partList = null;
		this.filteredList = [];
		
		this.stopObs();
	
		this.startObs();
	});
  }
  
  ngOnDestroy() {
	this.stopObs();
  }
  
  startObs() {
	this.subscriber = this.appComponent.getPartsObs({
		id: this.pId,
		part_id: this.uid,
	}).subscribe((partList : any) => {
		this.partList = partList;
		
		this.filter();
	}, err => {
		// show error
		this.apiService.handleStatusException(err);
	});
  }
  
  stopObs() {
	if(this.subscriber) {
		this.subscriber.unsubscribe();
	}
  }

  filter() {
	  if(!this.partList) {
		  return;
	  }
	  
	  if(!this.term) {
		  this.filteredList = this.partList;
		  return;
	  }
	  
	  var term = this.term.toLowerCase();
	  
	  var result = [];
	  
	  this.partList.forEach(item => {
		  if(item.name && item.name.toLowerCase().indexOf(term) > -1) {
			result.push(item);
			return;
		  }
		  
		  if(item.description && item.description.toLowerCase().indexOf(term) > -1) {
			result.push(item);
			return;
		  }
		  
		  var part = this.loadedParts[item.id]?.all_sub_parts.find(part => {
			  if(part.name && part.name.toLowerCase().indexOf(term) > -1) {
				return part;
			  }
			  
			  if(part.description && part.description.toLowerCase().indexOf(term) > -1) {
				return part;
			  }
		  });
		  
		  if(part) {
			result.push(item);
			return;
		  }
		  
		  var spec = item.specifications.find(spec => {
			  if(spec.name && spec.name.toLowerCase().indexOf(term) > -1) {
				return spec;
			  }
			  
			  if(spec.prices && spec.prices.find(x => x.amount >= parseFloat(term))) {
				return spec;
			  }
		  });
		  
		  if(spec) {
			result.push(item);
			return;
		  }
	  });
	  
	  this.filteredList = result;
  }

  add() {
    this.router.navigate(['product/part/show', { m_uid: this.uid, m_name: this.name
		, parent_uid: this.parentId, parent_name: this.parentName
		, p_uid: this.pId, p_name: this.pName }]);
  }

  edit(index, item) {
    this.router.navigate(['product/part/show', { uid: item.id, m_uid: this.uid, m_name: this.name
		, parent_uid: this.uid, parent_name: this.name
		, p_uid: this.pId, p_name: this.pName }]);
  }
  
  sub(item) {
    this.router.navigate(['product/part', { uid: item.id, name: item.name
		, parent_uid: this.uid, parent_name: this.name
		, p_uid: this.pId, p_name: this.pName }]);
  }

  remove(index, item) {	  
	if(item.deleting) {
		return;
	}
	
	Swal.fire({
		title: "Delete Product Part",
		text: "Do you want to delete " + item.name + "?",
		icon: "warning",
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value) {
			this.stopObs();
	
			item.deleting = true;
			
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.PRODUCT_PART_DELETE), {
					id: item.id
				}
			).then((res : any) => {
				this.partList.splice(index, 1);
				
				this.filter();
				
				item.deleting = false;		
				this.startObs();
			}, err => {
				item.deleting = false;
				this.startObs();
				Swal.fire("Error on Product Part Delete", this.apiService.handleStatusException(err, true), 'error');
			});
		}
	});
  }

  updateStatus(item, value) {
	if(item.updating) {
		return;
	}
	
	item.updating = true;
	this.stopObs();
	
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.PRODUCT_PART_ACTIVATION), {
			id: item.id,
			activation: value ? 1 : 0
		}
	).then((res : any) => {
		item.updating = false;
		this.startObs();
	}, err => {
		item.updating = false;
		this.startObs();
		Swal.fire("Error on Product Part Activation", this.apiService.handleStatusException(err, true), 'error');
	});
  }

  trackByFn(index, item) {
	return item.id;
  }
  
  transfer(index, item) {
	if(item.transfering) {
		return;
	}
	
	if(!this.productPartList) {
		Swal.fire("Product", "Product Or Part is still loading, please try later again.", "warning");
		return;
	}
	
	var options = {
		"": "Select Product Or Part",
		// "root": "New Product",
	};
		
	this.productPartList.forEach(product => {
		options["m_" + product.id] = product.name;
		
		product.parts.forEach(part => {
			if(part.specifications.length == 0) {
				options["p_" + part.id] = "- " + part.name;
			}
		});
	});
	
	Swal.fire({
		title: "Product Part Transfer",
		input: 'select',
		inputValue: item.parent_id || "",
		inputOptions: options,
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value && result.value != item.parent_id) {
			item.transfering = true;
			this.stopObs();
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.PRODUCT_PART_TRANSFER), {
					id: item.id,
					target_id: result.value,
				}
			).then((res : any) => {
				item.transfering = false;
				this.startObs();
				Swal.fire("Product Part Transfer", "Product Part Transferred.", 'success');
			}, err => {
				item.transfering = false;
				this.startObs();
				Swal.fire("Product Part Transfer", this.apiService.handleStatusException(err, true), 'error');
			});
		}
	});
  }
  
  assignment(index, item) {
	if(item.assigning) {
		return;
	}
	
	if(!this.productPartList) {
		Swal.fire("Product", "Product Or Part is still loading, please try later again.", "warning");
		return;
	}
	
	var options = {
		0: "No discount"
	};
	
	var product = this.productPartList.find(x => x.id == this.pId);
	
	if(!product) {
		Swal.fire("Error on Discount Sync", "Cannot find product on available list.", 'error');
		return;
	}
	
	product.discounts.forEach(discount => {
		options[discount.id] = discount.name + " " + discount.discount + "% - " + discount.addon + "%";
	});
	
	Swal.fire({
		title: "Discount Group Assignment",
		input: 'select',
		inputOptions: options,
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value) {
			item.assigning = true;
			this.stopObs();
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.PRODUCT_PART_ASSIGNMENT), {
					id: item.id,
					discount: result.value,
				}
			).then((res : any) => {
				item.assigning = false;
				this.startObs();
				Swal.fire("Discount Group Assignment", "Discount Group Assigned.", 'success');
			}, err => {
				item.assigning = false;
				this.startObs();
				Swal.fire("Discount Group Assignment", this.apiService.handleStatusException(err, true), 'error');
			});	
		}
	});
  }
  
  copy(index, item) {
	if(item.copying) {
		return;
	}
	
	if(!this.productPartList) {
		Swal.fire("Product", "Product Or Part is still loading, please try later again.", "warning");
		return;
	}
	
	var options = {
		0: "Select Product Or Part",
		// "root": "New Product",
	};
		
	this.productPartList.forEach(product => {
		options["m_" + product.id] = product.name;
		
		product.parts.forEach(part => {
			if(part.specifications.length == 0) {
				options["p_" + part.id] = "- " + part.name;
			}
		});
	});
	
	Swal.fire({
		title: "Product Part Copy",
		input: 'select',
		inputOptions: options,
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value) {
			item.assigning = true;
			this.stopObs();
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.PRODUCT_PART_COPY), {
					id: item.id,
					target_id: result.value,
				}
			).then((res : any) => {
				item.copying = false;
				this.startObs();				
				Swal.fire("Product Part Copy", "Product Part Copied.", 'success');
			}, err => {
				item.copying = false;
				this.startObs();
				Swal.fire("Product Part Copy", this.apiService.handleStatusException(err, true), 'error');
			});	
		}
	});
  }
  
  costing(index, item) {
	if(item.costing) {
		return;
	}
	
	Swal.fire({
		title: "Costing Percentage Mass Update",
		input: 'text',
		inputValidator: (val) => {
			if(val && isNaN(parseFloat(val))) {
				return "Must be numeric value.";
			}
		},
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.isConfirmed) {
			item.costing = true;
			this.stopObs();
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.PRODUCT_PART_COSTING), {
					id: item.id,
					percentage: result.value,
				}
			).then((res : any) => {
				item.costing = false;
				this.startObs();
				Swal.fire("Costing Percentage", "Costing Percentage Mass Updated.", 'success');
			}, err => {
				item.costing = false;
				this.startObs();
				Swal.fire("Costing Percentage", this.apiService.handleStatusException(err, true), 'error');
			});	
		}
	});
  }
  
  loopPart(array, part, level = 0) {
	part.level = level > 0 ? ("- ").padStart(level + 1, " ") : "";
	
	array.push(part);
	
	part.all_sub_parts.forEach(sub_part => {
		array = this.loopPart(array, sub_part, level + 1);
	});
	
	return array;
  }
  
  getRegion(region_id) {
	  var region = this.regionList?.find(x => x.id == region_id);
	  
	  if(region) {
		return " on " + region.name;
	  } else {
		  return "";
	  }
  }
  
  viewPart(item) {
	if(!item.viewing) {
		item.viewing = true;
		
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.PRODUCT_PART_GET_PART, [item.id])
		).then((product : any) => {
			this.loadedParts[product.id] = {
				parts: product.parts,
				all_sub_parts: product.all_sub_parts,
			};
			item.viewing = false;
		}, err => {
			item.viewing = false;
			Swal.fire("Product Part Viewing", this.apiService.handleStatusException(err, true), 'error');
		});
	}
  }

  groupPart(key) {
	if(!this.loadedParts[key]) {
		return;
	}
	
	var groupedParts = [];

	this.loadedParts[key].all_sub_parts.forEach(part => {
		this.loopPart(groupedParts, part);
	});

	return groupedParts;
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if(event.previousIndex != event.currentIndex) {
		this.stopObs();
		
		var target = this.filteredList[event.currentIndex];
		
		moveItemInArray(this.filteredList, event.previousIndex, event.currentIndex);
		
		// update server
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.PRODUCT_PART_REORDER), {
				product_id: this.pId,
				part_id: this.uid,
				id: this.filteredList[event.currentIndex].id,
				target_id: target.id,
				is_add: event.currentIndex > event.previousIndex ? 1 : 0,
			}
		).then((res : any) => {
			this.startObs();
		}, err => {
			this.startObs();
			Swal.fire("Product Part Re-Ordering", this.apiService.handleStatusException(err, true), 'error');
		});
	}
  }
}
