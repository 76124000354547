import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from './../../shared/api.service';
import { LocalizeService } from './../../shared/localize.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cw-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit {
  key;

  translations = {};
  
  sales_order;
  settingList = {};
  
  constructor(  
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
	private localizeService: LocalizeService,
	private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
	this.translations = this.localizeService.getTranslation('sales_order');
	
	this.key = this.aroute.snapshot.params['uid'];
	
	this.initFormData();
  }
  
  initSetting(regionId) {
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.SETTING_GET_MULTI), {
			keys: [
				'company_name',
				'company_email',
				'company_website',
				'company_address',
				'company_fax',
				'company_sst',
				'company_logo',
				'company_reg_no',
				'company_phone',
				'company_chop',
				'company_chop_text',
				'note',
				'deposit_percentage',
			],
			region_id: regionId
		}
	).then((settingList : any) => {
		this.settingList = settingList;
	}, err => {
		this.apiService.handleStatusException(err);
	});
  }

  async initFormData() {
	if(this.key) {
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.SALES_ORDER_GET, [this.key])
		).then((sales_order : any) => {
			this.sales_order = sales_order;
			
			this.initSetting(this.getVersion()?.region_id);
		}, err => {
			Swal.fire("Sales Order Viewing", this.apiService.handleStatusException(err), 'error');
		});
	}
  }
 
  getVersion() {
	  return this.sales_order?.quotation?.confirmed;
  }
  
  getSanitizerURL(path) {
	  return this.sanitizer.bypassSecurityTrustResourceUrl(APIService.STORAGE + path);
  }
  
  getSanitizerHTML(html) {
	  return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  
  getLines(text) {
	  return text.split("\n");
  }
  
  remove() {
	Swal.fire({
		title: "Delete Sales Order",
		text: "Do you want to delete " + this.sales_order.name + "?",
		icon: "warning",
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value) {
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.SALES_ORDER_DELETE), {
					id: this.sales_order.id
				}
			).then((res : any) => {
				if(this.sales_order.details) {
				} else {
					this.router.navigate(['sales_order']);
				}
			}, err => {
				Swal.fire("Error on Sales Order Delete", this.apiService.handleStatusException(err), 'error');
			});
		}
	});
  }
  
  pdf() {
	  if(this.sales_order) {
		window.open(APIService.SALES_ORDER_PDF + "?id=" + this.sales_order.id, "_blank");
	  }
  }
  
  share() {
	  if(this.sales_order) {
		window.open(APIService.SALES_ORDER_SHARE + "?id=" + this.sales_order.id, "_blank");
	  }
  }
}
