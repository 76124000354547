import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-standard-management',
  templateUrl: './standard-management.component.html',
  styleUrls: ['./standard-management.component.css']
})
export class StandardManagementComponent {
  @Input() addLinkName;
  @Input() addLink;
  @Input() pageName;
  @Input() cardName;
  @Input() icon;
  
  @Output() pageChange: EventEmitter<number> = new EventEmitter();
    
  onPageChange(page) {
	  this.pageChange.emit(page);
  }
}