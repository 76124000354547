import { Component, Input } from '@angular/core';
import { APIService } from './../api.service';
import Swal from 'sweetalert2';
import { moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-standard-quotation-table',
  templateUrl: './standard-quotation-table.component.html',
  styleUrls: ['./standard-quotation-table.component.css']
})
export class StandardQuotationTableComponent {
  @Input() quotation;
  @Input() current_version;
  
  processing;
  
  constructor(private apiService: APIService) { }
  
  parenthesesValue(value) {	  
	const stringValue = value >= 0 ? value.toFixed(2) : ("(" + Math.abs(value).toFixed(2) + ")");
	return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  
  getChar(i: number) {
	return String.fromCharCode(i + 65);
  }
  
  getTotalDiscount(parts) {
	const total = parts.reduce((sum, part) => sum + parseFloat(part.discounted_amount) || 0, 0);
	
	return total != 0 ? total : null;
  }
  
  getAllTotalDiscount() {
	const total = this.current_version?.items?.reduce((sum, item) => sum + item.item_parts?.reduce((pSum, part) => pSum + (part.discounted_amount > 0 ? 0 : parseFloat(part.discounted_amount || "0")), 0), 0);

	return total != 0 ? total : null;
  }
  
  summarizeDiscount(parts) {
	let discounts = parts.map(x => x.discount_id);
	
	if(discounts.length > 1) {
		discounts.forEach(discount_id => {
			// discount group different or is last with discount group
			var discountedParts = parts.filter(x => x.discount_id == discount_id);
		
			if(discountedParts.length > 0) {
				var discountData = {
					balance: 0,
					discounted: 0,
					name: discountedParts[0].discount_name,
					percent: discountedParts[0].discount,
					total: 0,
				};
				
				discountedParts.forEach(discountedPart => {
				discountData.total += discountedPart.total;
				discountData.discounted += discountedPart.discounted_amount;
				});
				
				discountData.balance = discountData.total + discountData.discounted;
				
				discountedParts[discountedParts.length - 1].discount_summary = discountData;
									
				discountedParts[0].start_discount = true;
			}
		});
	}
	
	return parts;
  }
  
  approve() {
	if(this.processing) {
		return;
	}
	
	this.processing = true;
	
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.QUOTATION_UPDATE), {
			id: this.quotation.id,
			version_id: this.current_version.id,
			discount_approved: true
		}
	).then((res : any) => {
		this.current_version.discount_approved = res.discount_approved;
		this.current_version.amount_words = res.amount_words;
		this.processing = false;
	}, err => {
		this.processing = false;
		Swal.fire("Quotation Discount Approval", this.apiService.handleStatusException(err, true), 'error');
	});
  }
  
  edit() {
	if(this.processing) {
		return;
	}
	
	Swal.fire({
		title: 'Edit Special Discount Amount',
		input: "text",
		inputValue: this.current_version.discounted_amount,
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.isConfirmed) {
			this.processing = true;
			
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.QUOTATION_UPDATE), {
					id: this.quotation.id,
					version_id: this.current_version.id,
					discounted_amount: result.value ? parseFloat(result.value) : 0,
					discount_approved: 1
				}
			).then((res : any) => {
				this.current_version.discounted_amount = res.discounted_amount;
				this.current_version.discount_approved = res.discount_approved;
				this.current_version.amount_words = res.amount_words;
				this.processing = false;
			}, err => {
				this.processing = false;
				Swal.fire("Quotation Discount Approval", this.apiService.handleStatusException(err, true), 'error');
			});
		}
	});
  }
  
  onDrop(evt : CdkDragDrop<string[]>, itemId) {
	if(evt.previousIndex === evt.currentIndex) {
		return;
	}
	const currentItem = this.current_version.items.find(x => x.id === itemId);
	if (!currentItem) {
		return;
	}
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.QUOTATION_PART_REORDER), {
			id: this.quotation.id,
			version_id: this.current_version.id,
			item_id: itemId,
			from: currentItem.item_parts[evt.previousIndex].id,
			to: currentItem.item_parts[evt.currentIndex].id,
		}
	).then((res : any) => {
		moveItemInArray(currentItem.item_parts, evt.previousIndex, evt.currentIndex);
	}, err => {
		Swal.fire("Re-ordering Quotation item", this.apiService.handleStatusException(err, true), 'error');
	});
  }
}
