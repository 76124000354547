<div class="main-content" style="margin-top: 0px">
    <div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<h3 style="margin-bottom: 30px">{{ pageName }}</h3>
			</div>
			<div *ngIf="addLink" class="col-lg-4 ml-auto">
				<a [routerLink]="addLink" class="btn btn-fill btn-rose" style="float: right;">{{ addLinkName || 'Add New' }}</a>
			</div>
            <div class="col-md-12">
                <div class="card">
					<div class="card-header card-header-primary card-header-icon">
						<div class="card-icon">
						  <i class="material-icons">{{ icon || 'assignment' }}</i>
						</div>
						<h4 class="card-title">{{ cardName || pageName }}</h4>
					</div>
                    <div class="card-body">
						<div class="toolbar">
							<ng-content select="[toolbar]"></ng-content>
						</div>
                        <div class="material-datatables">
							<table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
								<ng-content select="[table]"></ng-content>	
							</table>
                        </div>
						<div class="pull-right" style="margin-top: 15px">
							<pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
